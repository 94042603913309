import { Dropdown, Table, Form, Button, InputGroup } from "react-bootstrap";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  FilterCollapseIcon,
  RecieptIcon,
  PageHeaderListIcon,
  BookIcon,
  NoSelectedItemIcon,
  MoneyDollarIcon,
  EditIcon,
  DropdownEyeIcon,
  DeleteIcon,
  PDFIcon,
  ExcelIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useCurrencies,
  useIsAdmin,
  useIsCashier,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
  useSingleAndDoubleClick,
} from "../../utils/hooks";
import { services } from "../../config";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import { useMutation, useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  customerFullName,
  defaultSelectValue,
  paginationOptions,
  reactSelectTheme,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { useAuth } from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import CustomerSelector from "../utils/CustomerSelector";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { lowerCase } from "lodash";
import { isEmpty } from "lodash";
import NoTableItem from "../utils/NoTableItem";
import ExpensesSelector from "../utils/ExpensesSelector";
import PageHeader from "../PageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// ----------------------------------------------------
import { CSVLink } from "react-csv";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";

export default function Account() {
  useScrollTop();
  const navigate = useNavigate();
  const initialFilterParams = {
    Description: "",
    AccountID: "",
    /*startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    enableDateRange: true,*/
  };

  const { backendUrl } = useAuth();
  const [activeRow, setActiveRow] = useState(null);
  const [showExpensePopover, setShowExpensePopover] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const isAdmin = useIsAdmin();
  const isCashier = useIsCashier();

  const {
    deploymentCurrencies: currenciesOptions,
    prevailingCurrency,
  } = useCurrencies();
  const [excelData, setExcelData] = useState([]);
  const CSVLinkRef = useRef(null);
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 100,
    ...initialFilterParams,
    currency: "",
    convertCurrency: prevailingCurrency,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const fetchJournalDetailsByAccount = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/journal/account?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };

  const {
    data = { count: 0, journal: [] },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    [queryActions.JOURNAL_BY_ACCOUNT, queryParams],
    () => fetchJournalDetailsByAccount(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSelectedExpense = ({ AccountID, Description }) => {
    setFilterParams({
      ...filterParams,
      AccountID,
      Description,
    });
    setShowExpensePopover(false);
  };

  const { limit, page, ...rest } = queryParams;
  const newQuery = {
    ...rest,
  };

  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/journal/account?${queryString.stringify(rest)}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;
    const date = "";

    exData = exData.data.journal.map((el, i) => [
      el.DateLog ? format(new Date(el.DateLog), "dd MMM yyyy") : "...",
      el.AccountID,
      el.Ref,
      ![null, 0].includes(el.ignoredDebit)
        ? `(${currency(el.ignoredDebit, {
            symbol: "",
          }).format()})`
        : currency(el.Debit, {
            symbol: "",
          }).format(),
      ![null, 0].includes(el.ignoredCredit)
        ? `(${currency(el.ignoredCredit, {
            symbol: "",
          }).format()})`
        : currency(el.Credit, {
            symbol: "",
          }).format(),
    ]);

    const headers = [
      "Trans Date",
      "Account Type",
      "Remark",
      "Debit Amount",
      "Credit Amount",
    ];

    exData = [
      [company],
      [queryParams.Description],
      [date],
      [""],
      headers,
      ...exData,
      [""],
      [
        "Total Debit",
        currency(data?.debit, {
          symbol: "",
        }).format(),
      ],
      [
        "Total Credit",
        currency(data?.credit, {
          symbol: "",
        }).format(),
      ],
      [
        "Balance (LCY)",
        currency(data.balance, {
          symbol: "",
        }).format(),
      ],
    ];

    // console.log(exData);
    setExcelData(exData);
  };

  return (
    <>
      <main className="inner-page-outlet">
        <PageHeader
          name="Account Detail"
          description=""
          icon={<MoneyDollarIcon />}
        />
        <main className="cash-book">
          <div className="content">
            <div className="content-main">
              <div className={`filter-area ${showFilter ? "open" : "close"}`}>
                <div className="filter-header">
                  <h2>
                    <FilterTwoIcon /> Filters
                  </h2>

                  <Button
                    variant="white"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <FilterCollapseIcon />
                  </Button>
                </div>
                <div className="filter-body">
                  <Form>
                    <div className="body">
                      <Form.Group className="mb-2-5">
                        <Form.Label>Account ID</Form.Label>
                        <InputGroup>
                          <Form.Control
                            name="AccountID"
                            placeholder="Enter Account ID"
                            value={filterParams?.AccountID}
                            onChange={(e) => handleFilterParamsChange(e)}
                          />
                          <Popover
                            isOpen={showExpensePopover}
                            onClickOutside={() => setShowExpensePopover(false)}
                            content={() => (
                              <ExpensesSelector
                                handleSelectedExpense={handleSelectedExpense}
                                usage="chart of accounts"
                              />
                            )}
                            position="bottom"
                          >
                            <InputGroup.Text
                              onClick={() =>
                                setShowExpensePopover(!showExpensePopover)
                              }
                            >
                              <MagnifyIcon />
                            </InputGroup.Text>
                          </Popover>
                        </InputGroup>
                      </Form.Group>

                      <Form.Group className="mb-2-5">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          name="Description"
                          value={filterParams?.Description}
                          placeholder="Enter Description"
                          onChange={(e) => handleFilterParamsChange(e)}
                        />
                      </Form.Group>

                      <hr className="mt-3 mb-4" />

                      <div className="mb-2-5">
                        <Form.Check
                          inline
                          label="Enable Date Range"
                          name="enableDateRange"
                          type={"checkbox"}
                          id={`enableDateRange`}
                          checked={filterParams?.enableDateRange}
                          onChange={(e) => handleFilterParamsChange(e)}
                        />
                      </div>

                      <Form.Group className="mb-2-5">
                        <Form.Label>Date Range</Form.Label>
                        <div className="position-relative">
                          <RsDateRangePicker
                            placement="topStart"
                            value={
                              filterParams.startDate && filterParams.endDate
                                ? [
                                    parse(
                                      filterParams.startDate,
                                      "yyyy-MM-dd",
                                      new Date()
                                    ),
                                    parse(
                                      filterParams.endDate,
                                      "yyyy-MM-dd",
                                      new Date()
                                    ),
                                  ]
                                : []
                            }
                            onClean={() => clearDateRange()}
                            onOk={(date) => filterByDateRange(date)}
                          />
                        </div>
                      </Form.Group>

                      <hr />

                      <Form.Group className="mb-2-5">
                        <Form.Label>Filter By</Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          placeholder="All Currencies"
                          isSearchable={true}
                          options={currenciesOptions}
                          value={currenciesOptions.find(
                            (el) => el.value == filterParams.currency
                          )}
                          onChange={(selected) =>
                            setFilterParams({
                              ...filterParams,
                              page: 1,
                              currency: selected?.value,
                            })
                          }
                          isClearable={true}
                        />
                      </Form.Group>

                      <Form.Group className="">
                        <Form.Label>Report In</Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          placeholder="Select Currency"
                          isSearchable={true}
                          options={currenciesOptions}
                          value={currenciesOptions.find(
                            (el) => el.value === filterParams.convertCurrency
                          )}
                          onChange={({ value }) =>
                            setFilterParams({
                              ...filterParams,
                              page: 1,
                              convertCurrency: value,
                            })
                          }
                        />
                      </Form.Group>
                    </div>

                    <div className="buttons rounded">
                      <Button onClick={() => reset()} variant="white">
                        Reset
                      </Button>
                      <Button onClick={() => search()} variant="primary">
                        Search
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="content-body">
                <header>
                  <h1>
                    {!showFilter && (
                      <button
                        onClick={() => setShowFilter(!showFilter)}
                        className="btn filter"
                      >
                        <FilterTwoIcon />
                      </button>
                    )}
                    Account Detail
                    <button
                      title="Refresh"
                      onClick={() => refetch()}
                      className="btn text-primary"
                    >
                      <CachedIcon />
                    </button>
                  </h1>
                  <h2 className="m-0">{queryParams?.Description}</h2>
                  <div className="actions">
                    <CSVLink
                      className="btn print d-none"
                      filename={`${queryParams?.Description}_(${format(
                        new Date(),
                        "dd-MMM-yyyy hh:mm:ss a"
                      )}).csv`}
                      data={excelData}
                      ref={CSVLinkRef}
                    />

                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        className="btn print"
                        disabled={isfetchingExcel}
                        bsPrefix=""
                      >
                        Export
                        <ExportIcon color="#008000" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                        className="text-center"
                      >
                        <Dropdown.Item
                          as="div"
                          onClick={onDownloadExcelData}
                          className="p-cursor"
                        >
                          Excel <ExcelIcon color="#008000" />
                        </Dropdown.Item>
                        <Dropdown.Item as="div">
                          <a
                            href={`${backendUrl}/api/journal/pdf/account?${queryString.stringify(
                              newQuery
                            )}`}
                            target="blank"
                          >
                            PDF
                            <PDFIcon color="#ff0000" />
                          </a>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <button
                      onClick={() => setBtnSummaryActive(true)}
                      className="btn summaryBtn "
                    >
                      Show Summary
                    </button>
                  </div>
                </header>

                <div className="px-md-4">
                  <Table
                    responsive
                    borderless
                    striped
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        <th />
                        <th>Trans Date</th>
                        <th>Account Type</th>
                        <th>Remark</th>
                        <th>Debit</th>
                        <th>Credit</th>
                        <th>Username</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.journal.map((el, index) => (
                        <tr key={index}>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant=""
                                className="bg-white border-0"
                                bsPrefix="print more"
                              >
                                <DotsVerticalIcon />
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                popperConfig={{
                                  strategy: "fixed",
                                }}
                                renderOnMount
                                className="dropdown-with-icons"
                              >
                                <>
                                  <Dropdown.Item as="div">
                                    <Link
                                      to={`/journal-entry/edit/${el.TransID}`}
                                    >
                                      <EditIcon />
                                      Edit
                                    </Link>
                                  </Dropdown.Item>
                                </>{" "}
                                {el.Ref.includes("INVOICE") ? (
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() =>
                                      navigate(
                                        `/inventory-for-sales/manage-transaction`,
                                        {
                                          state: {
                                            TransactionID: el.TransID,
                                          },
                                        }
                                      )
                                    }
                                  >
                                    <DropdownEyeIcon className="text-light" />
                                    Open Transaction
                                  </Dropdown.Item>
                                ) : null}
                                {el.Ref.includes("EXPENSE") ? (
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() =>
                                      navigate(`/reports/expenses`, {
                                        state: {
                                          TransactionID: el.TransID,
                                        },
                                      })
                                    }
                                  >
                                    <DropdownEyeIcon className="text-light" />
                                    Open Expense
                                  </Dropdown.Item>
                                ) : null}
                                {isAdmin || isCashier ? (
                                  <Dropdown.Item as="div">
                                    <Link
                                      to={`/journal-entry/delete/${el.TransID}`}
                                    >
                                      <DeleteIcon />
                                      Delete
                                    </Link>
                                  </Dropdown.Item>
                                ) : null}
                              </Dropdown.Menu>{" "}
                            </Dropdown>
                          </td>
                          <td className="text-nowrap">
                            {el.DateLog
                              ? format(new Date(el.DateLog), "dd MMM yyyy")
                              : "..."}
                          </td>
                          <td>{el.AccountID}</td>
                          <td>{el.Ref}</td>
                          <td>
                            {![null, 0].includes(el.ignoredDebit)
                              ? `(${currency(el.ignoredDebit, {
                                  symbol: "",
                                }).format()})`
                              : currency(el.Debit, {
                                  symbol: "",
                                }).format()}

                            {/*  {currency(el.Debit, {
                            symbol: "",
                          }).format()} */}
                          </td>
                          <td>
                            {![null, 0].includes(el.ignoredCredit)
                              ? `(${currency(el.ignoredCredit, {
                                  symbol: "",
                                }).format()})`
                              : currency(el.Credit, {
                                  symbol: "",
                                }).format()}
                          </td>
                          <td>{el.Username || "..."}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {!isFetching && isSuccess && isEmpty(data?.journal) ? (
                    <NoTableItem queryParams={queryParams} />
                  ) : null}
                </div>

                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data.count / queryParams.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      document.body.scrollTop = document.documentElement.scrollTop = 0;
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>

                <div
                  className={
                    btnSummryActive
                      ? `summaryNew border-top open`
                      : `summaryNew border-top`
                  }
                >
                  <div className="summaryHeader">
                    <h2>Summary</h2>
                    {btnSummryActive && (
                      <button onClick={() => close()} className="btn p-0">
                        <SummaryCloseIcon />
                      </button>
                    )}
                  </div>

                  <div className="gridCont">
                    <div className="gridChild gridChildBorderLeftBlue">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(data?.debit, {
                            symbol: "",
                          }).format()}
                        </p>
                        <p className="gridChld2">Total Debit</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconUp />
                        <p>5%</p>
                      </div>
                    </div>

                    <div className="gridChild gridChildBorderLeftOrange">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(data?.credit, {
                            symbol: "",
                          }).format()}
                        </p>
                        <p className="gridChld2">Total Credit</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconUp />
                        <p>5%</p>
                      </div>
                    </div>

                    <div className="gridChild gridChildBorderLeftBrown">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(data?.balance, {
                            symbol: "",
                          }).format()}
                        </p>
                        <p className="gridChld2">Balance (LCY)</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconDown />
                        <p>5%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </main>
    </>
  );
}
