import React, { useEffect, useState } from "react";
import { Modal, Table, Form, Button, Dropdown } from "react-bootstrap";
import CachedIcon from "mdi-react/CachedIcon";
import currency from "currency.js";
import Datetime from "react-datetime";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { useMutation, useQuery } from "react-query";
import queryString from "query-string";

import { format, startOfMonth, parse, differenceInWeeks } from "date-fns";
import TableComponent from "../TableComponent";
import NoTableItem from "../utils/NoTableItem";
import { isEmpty } from "lodash";
import useDebounce, { useQueryParams } from "../../utils/hooks";
import {
  fetchActionsUtil,
  formatDate,
  paginationOptions,
} from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";
import ReactPaginate from "react-paginate";

export default function EditBatchHistoryModal({
  setShowBatchHistoryModal,
  batchName,
  showBatchHistoryModal,
  setBatchName,
  title,
}) {
  const { backendUrl, token } = useAuth();
  const initialFilterParams = {
    startDate: "",
    endDate: "",
    batchName,
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const { data, refetch, isFetching, isSuccess } = useQuery(
    ["GET_ADJUSTMENT_LOG", queryParams],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/items/adjustment-log?${queryString.stringify(
          queryParams
        )}`,
        "GET"
      ),

    {
      enabled: true,
      keepPreviousData: true,
    }
  );
  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams, setQueryParams]);

  useEffect(() => {
    reset();
  }, []);
  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };
  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };
  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const tableHead = () => {
    return (
      <thead className="position-sticky top-0">
        <tr>
          <th />
          <th>User</th>

          <th>Item</th>

          <th>Old Qty</th>
          <th>Old Total Item Qty</th>
          <th>Old Unit Cost</th>
          <th>Old Expiry Date</th>
          <th>New Qty</th>
          <th>New Total Item Qty</th>
          <th>New Unit Cost</th>
          <th>New Expiry Date</th>
          <th>Date Created</th>
        </tr>
      </thead>
    );
  };

  const tableData = (el, index) => {
    return (
      <>
        <td />
        <td>{el?.name}</td>
        <td>{el?.itemName}</td>

        <td>{el?.oldQty}</td>
        <td>{el?.oldTotalItemQty ? el?.oldTotalItemQty : "..."}</td>
        <td>{el?.oldUnitCost}</td>
        <td>{formatDate(el?.oldExpiryDate)}</td>
        <td>{el?.newQty}</td>
        <td>{el?.newTotalItemsQty ? el?.newTotalItemsQty : "..."}</td>
        <td>{el?.newUnitCost}</td>
        <td>{formatDate(el?.newExpiryDate)}</td>
        <td>
          {formatDate(el?.Date)} {formatDate(el?.Date, "k:mm:ss")}{" "}
        </td>
      </>
    );
  };

  return (
    <Modal
      show={showBatchHistoryModal}
      onHide={() => {
        setBatchName(null);
        setShowBatchHistoryModal(false);
      }}
      //   dialogClassName="requisition-details-modal approve-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h5">Adjustment Log History</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 d-flex flex-column pt-0">
        <div className="search-area d-flex justify-content-between align-items-center gap-3 w-100 px-3 mb-2 h6">
          <h2 className="h6">
            {batchName}
            <button
              title="Refresh"
              className="btn text-primary"
              onClick={() => refetch()}
            >
              <CachedIcon />
            </button>
          </h2>
        </div>
        <div className="col-6 d-flex gap-3 align-items-center pe-3 mb-3">
          <div className="global-search-area w-75">
            <MagnifyIcon />
            <input
              className="form-control search-input"
              name="q"
              onChange={(e) => handleSearchQueryChange(e)}
              value={queryParams.q}
              autoFocus
              autoComplete="off"
              placeholder="Search by  staff name "
              type={"text"}
            />
          </div>

          <Form.Group className="">
            <div className="position-relative">
              <RsDateRangePicker
                placement="bottomStart"
                value={
                  filterParams.startDate && filterParams.endDate
                    ? [
                        parse(filterParams.startDate, "yyyy-MM-dd", new Date()),
                        parse(filterParams.endDate, "yyyy-MM-dd", new Date()),
                      ]
                    : []
                }
                onClean={() => clearDateRange()}
                onOk={(date) => filterByDateRange(date)}
              />
            </div>
          </Form.Group>
        </div>

        <div className=" my-3">
          <TableComponent
            responsive
            borderless
            striped
            tableHeadsFunction={tableHead}
            mainDataArray={data?.data || []}
            className="product-table text-nowrap"
            tableDataRowFunction={tableData}
            // tableRowClick={(e, el) => manageTransaction(e, el)}
          />

          {!isFetching && isSuccess && isEmpty(data?.data) ? (
            <NoTableItem queryParams={queryParams} />
          ) : null}
        </div>

        <div className="d-flex justify-content-between px-3 align-items-center pagination">
          <div className="pagination_left">
            <p className="m-0 p-0">Show</p>
            <select
              value={queryParams.limit}
              name="limit"
              className="form-select "
              onChange={(e) => handleSearchQueryChange(e)}
            >
              <option value="10">10 rows</option>
              <option value="20">20 rows</option>
              <option value="30">30 rows</option>
              <option value="40">40 rows</option>
              <option value="50">50 rows</option>
              <option value="100">100 rows</option>{" "}
            </select>
          </div>

          <ReactPaginate
            {...paginationOptions}
            pageCount={Math.ceil(data?.count / queryParams.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={0}
            onPageChange={({ selected }) => {
              document.body.scrollTop = document.documentElement.scrollTop = 0;
              setQueryParams({
                ...queryParams,
                page: selected + 1,
              });
            }}
            forcePage={queryParams.page - 1}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
