import { useEffect, useState, useRef, Fragment, useMemo } from "react";
import {
  Button,
  Modal,
  Table,
  InputGroup,
  Popover,
  Overlay,
  Form,
} from "react-bootstrap";
import "./../../assets/scss/requisition/index.scss";
import { DropdownCloseIcon, Logo, PrintIcon, SignatureIcon } from "../Icons";
import { useNavigate, useParams } from "react-router-dom";
import { backendApis, services } from "../../config";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { format } from "date-fns";
import CurrencyInput from "react-currency-input-field";
import { find, isEmpty, lowerCase, uniqBy } from "lodash";
import { isInventoryOrFundRequest, nanoid, waitFor } from "../../utils/helpers";
import useDebounce, { useCurrencies, useEffectOnce } from "../../utils/hooks";
import { toast } from "react-toastify";
import { cacheTimes, queryActions } from "../../utils/reactQueryActions";
import currency from "currency.js";

import ItemsTable from "../utils/ItemsTable";
import { useAuth } from "../../hooks/useAuth";
import { FieldArray, FormikProvider, useFormik } from "formik";
import RequisitionService from "../../hooks/requisitionService";
import * as yup from "yup";
import eventBus from "../../utils/EventBus";
import _ from "lodash";
import AsyncSelect from "react-select/async";
import { useStoreActions, useStoreState } from "easy-peasy";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import Select from "react-select";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import Datetime from "react-datetime";
import ConfirmDialog from "../ConfirmDialogue";
import { StaffSelectorMultiple } from "../GeneralSettingsModal";

function Attachments({ files = [], setFiles }) {
  //  const [files, setFiles] = useState([]);
  const { backendUrl } = useAuth();
  return (
    <div>
      <Form.Label className="btn btn-light-blue">
        <span>Add Files </span>
        <Form.Control
          type="file"
          className="d-none"
          multiple
          onChange={(e) => setFiles([...files, ...e.target.files])}
        />
      </Form.Label>

      <div>
        {files?.map((el, index) => (
          <div key={index}>
            {el?.fileId ? (
              <a
                href={`${backendUrl}/api/requisition/files/${
                  el.systemFileName
                }/${el.name}`}
                target="_blank"
                rel="noreferrer"
              >
                {el?.name}
              </a>
            ) : (
              <span>{el?.name}</span>
            )}

            <Button
              variant=""
              size="sm"
              onClick={(e) => setFiles(files.filter((el, i) => i !== index))}
            >
              <DropdownCloseIcon />
            </Button>
            <hr className="m-0" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default function NewFundAndInventoryModal({
  location,
  requisition,
  setShowNewRequisitionModal,
  previousFormValues,
  selectedJob = {},
  handleCreatedRequisition,
  itemsRequested = [],
}) {
  const initialRequestData = (requisition = {}) => {
    return lowerCase(requisition?.type) === "fund request" ||
      lowerCase(requisition?.type) === "retirement for fund request" ||
      lowerCase(requisition?.type) === "budget"
      ? {
          description: "",
          unitCost: "",
          quantity: "",
        }
      : lowerCase(requisition?.type) === "material request"
      ? {
          barcode: "",
          itemName: "",
          description: "",
          quantityRequested: "",
          availability: "",
          quantityApproved: "",
        }
      : { barcode: "", itemName: "", unitCost: "", quantity: "" };
  };

  let { id } = useParams();
  const { deploymentCurrencies: currenciesOptions } = useCurrencies();
  const generalSettings = useStoreState((state) => state.generalSettings);
  const { createRequisitionMutation } = RequisitionService();
  const [tableData, setTableData] = useState(itemsRequested);
  const [total, setTotal] = useState(0);
  const [fundRequest, setFundRequest] = useState(
    initialRequestData(previousFormValues)
  );
  const { user: authUser, backendUrl } = useAuth();
  const [file, setFile] = useState();
  const [reciever, setReceiver] = useState();
  const branches = useMemo(
    () =>
      // .filter((el) => el.name !== authUser.company),
      backendApis.map((el) => ({
        ...el,
        label: el.name,
        value: el.name,
      })),
    [authUser]
  );

  const handleInputChange = ({ index, name, value }) => {
    const oldTableData = tableData;
    oldTableData[index][name] = value;
    setTableData([...oldTableData]);
  };

  const calculateTotal = (tableData) => {
    setTotal(
      tableData
        .map((el) =>
          currency(el?.unitCost, {
            precision: 2,
          }).multiply(el?.quantity)
        )
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        )
    );
  };

  const debouncedTableData = useDebounce(tableData, 800);
  useEffect(() => {
    if (debouncedTableData) calculateTotal(debouncedTableData);
  }, [debouncedTableData]);

  const save = (formValues) => {
    // if (!file) toast.error("Please attach your signature");
    let description = "";
    if (
      lowerCase(previousFormValues.type) === "fund request" ||
      lowerCase(previousFormValues?.type) === "retirement for fund request" ||
      lowerCase(previousFormValues.type) === "budget"
    ) {
      description = [
        ...tableData,
        // add if form has data
        ...(fundRequest.description ? [fundRequest] : []),
      ]
        .map((el) => {
          delete el.subtotal;
          return Object.values(el).join(",,,");
        })
        .join("&&&");
    } else if (lowerCase(previousFormValues.type) === "inventory") {
      description = tableData
        .map((el) => {
          delete el.subtotal;
          return Object.values(el).join(",,,");
        })
        .join("&&&");
    } else if (lowerCase(previousFormValues.type) === "material request") {
      // console.log(fundRequest);
      description = [
        ...tableData,
        // add if form has data
        ...(fundRequest?.itemName
          ? [{ ...fundRequest, barcode: nanoid() }]
          : []),
      ]
        .map((el) => {
          delete el.subtotal;
          return Object.values(el).join(",,,");
        })
        .join("&&&");

      //   console.log(description);
      // return;
    }

    description = description + "&&&";

    formValues.requestto = formValues.recipient;
    formValues.regdate = format(new Date(), "yyyy-MM-dd");
    formValues.status = "Pending";
    formValues.preSelectedSignatory = JSON.stringify(
      formValues.preSelectedSignatory
    );

    if (
      ["material request", "inventory"].includes(lowerCase(formik.values.type))
    ) {
      formValues.branchUrl = branches.find(
        (el) => formik.values.branch === el.name
      )?.url;

      // Cross branch requisition
      if (formValues.branchUrl != backendUrl) {
        formValues.sentByName = authUser.Name;
        formValues.receivedByName = data.staffs.find(
          (el) => el.Staff_ID === formValues.recipient
        )?.Name;
      } else {
        formValues.branchUrl = "";
      }
    }

    const realTotal = currency(fundRequest?.unitCost)
      .multiply(fundRequest?.quantity)
      .add(total).value;

    // are the users pre-selected

    createRequisitionMutation.mutate(
      {
        requestid: id,
        description,
        file,
        amount: isNaN(realTotal) ? 0 : realTotal,
        requiredSignatoriesStaff_ID: JSON.stringify(
          lowerCase(previousFormValues.type) === "fund request"
            ? generalSettings.fundRequestMandatorySignatory
            : lowerCase(previousFormValues.type) === "material request"
            ? generalSettings.materialRequestMandatorySignatory
            : []
        ),
        ...formValues,
      },
      {
        onSuccess: (data) => {
          formik.resetForm();
          eventBus.dispatch("REQUISITION_CREATED", data.requisition);
          if (handleCreatedRequisition) handleCreatedRequisition();
          setShowNewRequisitionModal(false);
        },
      }
    );
  };

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [activeTableIndex, setActiveTableIndex] = useState(null);
  const ref = useRef(null);

  const handleRowClick = (e, index) => {
    setShow(true);
    setTarget(e.target);
    //  setActiveTableIndex(index);
  };

  const createNew = (e) => {
    setShow(true);
    setTarget(e.target);
    setActiveTableIndex(-1);
  };

  const handleSelectedItem = (item) => {
    console.log(item);
    /* Bar_Code: "0003";
    Branch: "HQ";
    Cat_Name: "A2 Lubricant";
    Item_Name: "A2 Oil 1L";
    Quantity: 12;
    UnitCost: 1450;
    UnitPrice: 1650;
    imagepath: ""; */
    if (lowerCase(previousFormValues.type) === "inventory") {
      item = {
        barcode: item.Bar_Code,
        itemName: item.Item_Name,
        unitCost: item.UnitCost,
        quantity: 1,
      };
    } else if (lowerCase(previousFormValues.type) === "material request") {
      item = {
        barcode: item.Bar_Code,
        itemName: item.Item_Name,
        description: "",
        quantityRequested: "",
        availability: "",
        quantityApproved: "",
      };
    } else if (lowerCase(previousFormValues.type) === "fund request") {
    }

    let oldTableData = tableData;

    if (activeTableIndex === -1) {
      oldTableData = [...oldTableData, item];
    } else {
      oldTableData[activeTableIndex] = item;
    }
    setTableData([...oldTableData]);
    if (activeTableIndex === -1) setActiveTableIndex(oldTableData.length - 1);
    setShow(false);
  };

  const removeSelection = () => {
    const oldTableData = tableData.filter(
      (el, index) => index !== activeTableIndex
    );
    setTableData([...oldTableData]);
  };

  const handleFundRequestInput = (value, name) => {
    console.log(value, name);
    setFundRequest({
      ...fundRequest,
      [name]: value,
    });
  };

  const createNewFundRequest = (e) => {
    if (!e.target.value) return;
    if (lowerCase(requisition?.type) === "material request") {
      if (isEmpty(fundRequest.barcode)) {
        fundRequest.barcode = nanoid();
      }
    }
    setTableData([...tableData, fundRequest]);
    e.target.blur();

    setTimeout(() => {
      console.log(initialRequestData(previousFormValues), previousFormValues);
      setFundRequest(() => ({ ...initialRequestData(previousFormValues) }));
    }, 10);
  };

  const formik = useFormik({
    initialValues: {
      title: previousFormValues.title,
      type: previousFormValues.type,
      department: previousFormValues.department,
      recipient: previousFormValues.recipient,
      jobGrade: previousFormValues.jobGrade,
      comment: previousFormValues.comment,
      vendor: previousFormValues.vendor,
      jobNumber: previousFormValues.jobNumber,
      paymentType: "",
      currency: generalSettings?.prevailingCurrency,
      conversionAmount: generalSettings.dollarInBaseCurrency,
      expectedDeliveryDate: new Date(),
      projectDescription: "",
      initialFiles: [],
      branch: branches.find((el) => authUser?.company === el.name)?.name,
      requesterAccountName: "",
      requesterAccountNumber: "",
      requesterBank: "",
      attachedRequestid: previousFormValues.attachedRequestid,
      preSelectedSignatory: previousFormValues?.preSelectedSignatory,
    },
    validationSchema: yup.object().shape({
      title: yup.string().required(),
      type: yup.string().required(),
      // department: yup.string().required(),
      // recipient: yup.string().required(),
      comment: yup.string(),
      //  vendor: yup.string().required(),
    }),
    onSubmit: async (values) => {
      /* if (values.preSelectSignatory?.length > 1) {
        const nextUser = values.preSelectedSignatory[1];
        if (nextUser?.staff) {
          values.recipient = nextUser.staff;
        }
        if (isEmpty(values.recipient)) {
          values.Department = nextUser.department;
        }
      } */

      if (isEmpty(values.comment)) values.comment = "...";
      if (
        isEmpty(values.recipient) &&
        !(await ConfirmDialog({
          title: "Recipient not Selected",
          description: "Continue?",
        }))
      ) {
        return;
      }

      save(values);
    },
  });

  /*  const getDepartments = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/users/departments`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.departments = [
      { label: "Select Recipient's Department", value: "" },
      ...data.departments.map((el) => ({
        ...el,
        label: el.Department,
        value: el.Department,
      })),
    ];
    return data;
  };
  const { data: { departments } = { departments: [] } } = useQuery(
    [queryActions.GET_DEPARTMENTS],
    () => getDepartments(),
    {}
  ); */

  const getUsersInDepartments = async (department) => {
    let response = await fetch(
      `${backendUrl}/api/users/by-department/${department}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.users = [
      { label: "Select Recipient", value: "" },
      ...data.users
        .map((el) => ({
          ...el,
          label: el.Name,
          value: el.Staff_ID,
        }))
        .filter((el) => el.Staff_ID !== authUser.Staff_ID),
    ];
    return data;
  };
  const {
    data: { users } = { users: [] },
    refetch: getUsers,
    isFetching: isLoadingUsers,
  } = useQuery(
    [queryActions.GET_USERS_IN_DEPARTMENTS, formik.values.department],
    () => getUsersInDepartments(formik.values.department),
    {
      enabled: false,
    }
  );

  const fetchVendors = async () => {
    let response = await fetch(`${backendUrl}/api/vendors`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.vendors = [
      { label: "None", value: "" },
      ...data.vendors.map((el) => ({
        ...el,
        label: `  ${el.CompanyName} / ${el.Vendor_ID}`,
        value: `  ${el.CompanyName} / ${el.Vendor_ID}`,
      })),
    ];
    return data;
  };
  const {
    data: { vendors } = { vendors: [] },
    isFetching: isLoadingVendors,
  } = useQuery([queryActions.GET_VENDORS], () => fetchVendors(), {
    enabled: true,
  });

  /*  useEffect(() => {
    if (formik.values.department) {
      getUsers();
    }
  }, [formik.values.department]); */

  const fetchJobs = async (inputValue, callback) => {
    let response = await fetch(
      `${backendUrl}/api/job?page=1&Limit=50&Title=${inputValue}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    if (data.jobs)
      callback(
        (data.jobs = data.jobs.map((el) => ({
          ...el,
          label: el.title,
          value: el.jobNumber,
        })))
      );
  };

  const debouncedFetchJobs = _.debounce(fetchJobs, 500);

  const loadOptions = (inputValue, callback) => {
    debouncedFetchJobs(inputValue, callback);
  };

  const currencySymbol = useMemo(() => {
    const foundCurrency = currenciesOptions.find(
      (el) => el.cc === formik.values.currency
    );
    return foundCurrency ? foundCurrency.symbol : "";
  }, [formik.values.currency]);

  /* const getUsersInSystem = async () => {
    let response = await fetch(`${backendUrl}/api/users`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const data = await response.json();
    data.allUsersInSystem = [
      {
        label: "None",
        value: "",
      },
      ...data.staff.map((el) => ({
        ...el,
        label: el.Name,
        value: el.Staff_ID,
      })),
    ];
    console.log(data);
    return data;
  };
  const { data: { allUsersInSystem } = { allUsersInSystem: [] } } = useQuery(
    ["GET_USERS_IN_SYSTEM"],
    () => getUsersInSystem(),
    {}
  ); */

  //----------------------------------------------------------------------
  // fetch data for form
  const getDepartments = async ({ branch }) => {
    let response = await fetch(
      `${
        branches.find((el) => branch === el.name)?.url
      }/api/users/departments-with-users`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const {
    data = { departments: [], staffs: [], jobGrades: [] },
    isFetching: isFetchingStaffs,
  } = useQuery(
    [queryActions.GET_USERS_WITH_DEPARTMENTS, formik.values.branch],
    () =>
      getDepartments({
        branch: formik.values.branch,
      }),
    {
      enabled: true,
      keepPreviousData: true,
      cacheTime: cacheTimes.GET_USERS_WITH_DEPARTMENTS,
    }
  );

  const staffSelectOptions = useMemo(() => {
    const jobGrades = data.staffs
      .filter((el) => el.Department === formik.values.department)
      .map((el) => ({ jobGrade: el.jobGrade }));

    const staffs = data.staffs.filter((el) => {
      if (!formik.values.department) return true;
      if (!formik.values.department && !formik.values.jobGrade) return true;
      if (formik.values.department && !formik.values.jobGrade) {
        return el.Department === formik.values.department;
      }
      if (formik.values.department && formik.values.jobGrade) {
        return (
          el.Department === formik.values.department &&
          el.jobGrade === formik.values.jobGrade
        );
      }
    });

    return {
      departments: [
        { label: "Any Department", value: "" },
        ...data.departments.map((el) => ({
          label: el.Department,
          value: el.Department,
        })),
      ],
      jobGrades: [
        { label: "Any Job Grade", value: "" },
        ...uniqBy(jobGrades, "jobGrade").map((el) => ({
          label: el.jobGrade,
          value: el.jobGrade,
        })),
      ],
      staffs: [
        { label: "Any Staff", value: "" },
        ...staffs.map((el) => ({
          label: el.Name,
          value: el.Staff_ID,
        })),
      ],
    };
  }, [
    data,
    formik.values.department,
    formik.values.jobGrade,
    formik.values.branch,
  ]);

  // GET FORM PERMISION
  const getNumberSystems = async () => {
    let response = await fetch(`${backendUrl}/api/numbersystem`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    await waitFor(100);

    if (!isEmpty(formik.values.preSelectedSignatory)) {
      const permissions = formik.values.preSelectedSignatory;

      if (permissions) {
        setReceiver(permissions[1]);
        formik.setFieldValue("department", permissions[1].department);
        formik.setFieldValue("recipient", permissions[1].staff);
        formik.setFieldValue("jobGrade", permissions[1].jobGrade);
      }
    } else if (data?.numberSystems) {
      const permissions = JSON.parse(
        data.numberSystems.find(
          (el) => el?.documentType === formik.values?.type
        )?.selectedSignatory || []
      );

      if (permissions) {
        setReceiver(permissions[1]);
        formik.setFieldValue("department", permissions[1].department);
        formik.setFieldValue("recipient", permissions[1].staff);
        formik.setFieldValue("jobGrade", permissions[1].jobGrade);
      }
    }

    return data;
  };

  const { refetch: refetchPermissions } = useQuery(
    ["NUMBER_SYSTEMS"],
    () => getNumberSystems(),
    {
      keepPreviousData: true,
    }
  );
  // ---

  return (
    <div>
      <Modal
        show={true}
        onHide={() => setShowNewRequisitionModal(false)}
        dialogClassName="requisition-details-modal preview-modal create-form"
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
        // fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="py-2 capitalize">{previousFormValues.type}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="preview p-0">
          <div className="preview-content p-0">
            <div className="content">
              <div className="preview-body">
                <FormikProvider value={formik}>
                  <Form
                    className="mb-3"
                    id="requisition-form"
                    noValidate
                    onSubmit={formik.handleSubmit}
                  >
                    <Form.Group className="mb-3">
                      <Form.Label>Title :</Form.Label>
                      <Form.Control
                        name="title"
                        placeholder="Enter a title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.title && !!formik.errors.title
                        }
                      />

                      <Form.Control.Feedback type="invalid">
                        {formik.errors.title}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <div className="row gap-3">
                      {formik.values.type !== "Budget" && (
                        <Form.Group className="col mb-3">
                          <Form.Label>Vendor :</Form.Label>
                          <Select
                            classNamePrefix={"form-select"}
                            name="vendor"
                            options={vendors}
                            value={vendors.find(
                              (el) => el.value === formik.values.vendor
                            )}
                            onChange={(selected) =>
                              formik.setFieldValue("vendor", selected?.value)
                            }
                            isDisabled={
                              lowerCase(previousFormValues.type) ===
                              "retirement for fund request"
                            }
                          />

                          <Form.Control.Feedback type="invalid">
                            {formik.errors.vendor}
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}

                      {["fund request", "material request", "budget"].includes(
                        lowerCase(previousFormValues.type)
                      ) ? (
                        <>
                          <Form.Group className="col mb-3">
                            <Form.Label>Job / Contract :</Form.Label>

                            <AsyncSelect
                              classNamePrefix={"form-select"}
                              className="customer-select-dropdown"
                              menuPlacement="bottom"
                              menuPosition="fixed"
                              isSearchable={true}
                              isClearable={true}
                              defaultOptions
                              onChange={(selection) => {
                                formik.setFieldValue(
                                  "jobNumber",
                                  selection?.value
                                );
                              }}
                              loadOptions={loadOptions}
                              defaultValue={
                                !isEmpty(selectedJob) ? selectedJob : {}
                              }
                            />
                          </Form.Group>
                        </>
                      ) : null}
                    </div>

                    <div className="row gap-3">
                      {["material request", "inventory"].includes(
                        lowerCase(formik.values.type)
                      ) &&
                      branches?.length > 1 &&
                      isEmpty(reciever) ? (
                        <Form.Group className="col mb-3">
                          <Form.Label>Branch :</Form.Label>
                          <Select
                            classNamePrefix={`form-select`}
                            name="department"
                            options={branches}
                            value={branches.find(
                              (el) => el.value === formik.values.branch
                            )}
                            onChange={({ value }) => {
                              formik.setFieldValue("branch", value);

                              // clear others
                              formik.setFieldValue("department", "");
                              formik.setFieldValue("jobGrade", "");
                              formik.setFieldValue("recipient", "");
                            }}
                          />
                        </Form.Group>
                      ) : null}

                      <Form.Group className="col mb-3">
                        <Form.Label>Department :</Form.Label>
                        <Select
                          classNamePrefix={`form-select`}
                          name="department"
                          options={staffSelectOptions?.departments}
                          value={staffSelectOptions?.departments.find(
                            (el) => el.value === formik.values.department
                          )}
                          onChange={({ value }) => {
                            formik.setFieldValue("department", value);

                            // clear others
                            formik.setFieldValue("jobGrade", "");
                            formik.setFieldValue("recipient", "");
                          }}
                          isDisabled={reciever?.department}
                          isLoading={isFetchingStaffs}
                        />
                      </Form.Group>

                      <Form.Group className="col mb-3">
                        <Form.Label>Job Grade :</Form.Label>
                        <Select
                          classNamePrefix={`form-select`}
                          name="jobGrade"
                          options={staffSelectOptions?.jobGrades}
                          value={staffSelectOptions?.jobGrades.find(
                            (el) => el.value === formik.values.jobGrade
                          )}
                          onChange={({ value }) => {
                            formik.setFieldValue("jobGrade", value);

                            // clear others
                            formik.setFieldValue("recipient", "");
                          }}
                          isDisabled={reciever?.jobGrade || reciever?.staff}
                          isLoading={isFetchingStaffs}
                        />
                      </Form.Group>

                      <Form.Group className="col mb-3">
                        <Form.Label>Recipient :</Form.Label>
                        <Select
                          classNamePrefix={`form-select`}
                          name="recipient"
                          options={staffSelectOptions?.staffs}
                          value={staffSelectOptions?.staffs.find(
                            (el) => el.value === formik.values.recipient
                          )}
                          onChange={({ value }) =>
                            formik.setFieldValue("recipient", value)
                          }
                          isDisabled={reciever?.staff}
                          isLoading={isFetchingStaffs}
                        />
                      </Form.Group>
                    </div>

                    <Form.Group className="my-3">
                      <Form.Label>Comment :</Form.Label>
                      <label className="border p-0 rounded message-area d-block">
                        <Form.Control
                          name="comment"
                          value={formik.values.comment}
                          onChange={formik.handleChange}
                          as="textarea"
                          className="w-100 border-0 p-3"
                          placeholder="Enter a comment"
                          rows={4}
                        />
                      </label>
                    </Form.Group>
                  </Form>
                </FormikProvider>

                <Attachments
                  files={formik.values.initialFiles}
                  setFiles={(files) =>
                    formik.setFieldValue("initialFiles", files)
                  }
                />
                <div className="my-2" />

                {lowerCase(previousFormValues.type) === "material request" ? (
                  <>
                    <hr />
                    <Form.Group className="mb-3">
                      <Form.Label>Project Description :</Form.Label>
                      <label className="border p-0 rounded message-area d-block">
                        <Form.Control
                          name="projectDescription"
                          value={formik.values.projectDescription}
                          onChange={formik.handleChange}
                          as="textarea"
                          className="w-100 border-0 p-3"
                          placeholder="Enter a Project  Description"
                          rows={4}
                        />
                      </label>
                    </Form.Group>
                    <Table
                      responsive
                      borderless
                      striped
                      className="product-table"
                    >
                      <thead>
                        <tr>
                          <th>S/N</th>
                          <th>Items/Materials</th>
                          <th>Specifications</th>
                          <th>Quantity Requested</th>
                          <th>Availability</th>
                          <th>Quantity Approved</th>
                        </tr>
                      </thead>

                      <tbody>
                        {tableData &&
                          tableData.map((el, index) => (
                            <tr
                              key={index}
                              className={`${activeTableIndex === index &&
                                "active-row"}`}
                              onClick={() =>
                                activeTableIndex !== index &&
                                setActiveTableIndex(index)
                              }
                            >
                              <td>{index + 1}</td>
                              <td>
                                <input
                                  className="form-control border-0 px-1 bg-white"
                                  value={el.itemName}
                                  onKeyDown={(e) =>
                                    e.keyCode === 13 && e.target.blur()
                                  }
                                  onChange={(e) =>
                                    handleInputChange({
                                      index,
                                      name: "itemName",
                                      value: e.target.value,
                                    })
                                  }
                                />
                              </td>{" "}
                              <td>
                                <input
                                  className="form-control border-0 px-1 bg-white"
                                  value={el.description}
                                  onKeyDown={(e) =>
                                    e.keyCode === 13 && e.target.blur()
                                  }
                                  onChange={(e) =>
                                    handleInputChange({
                                      index,
                                      name: "description",
                                      value: e.target.value,
                                    })
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control border-0 px-1 bg-white"
                                  value={el.quantityRequested}
                                  onKeyDown={(e) =>
                                    e.keyCode === 13 && e.target.blur()
                                  }
                                  onChange={(e) =>
                                    handleInputChange({
                                      index,
                                      name: "quantityRequested",
                                      value: e.target.value,
                                    })
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control border-0 px-1 bg-white"
                                  value={el.availability}
                                  onKeyDown={(e) =>
                                    e.keyCode === 13 && e.target.blur()
                                  }
                                  onChange={(e) =>
                                    handleInputChange({
                                      index,
                                      name: "availability",
                                      value: e.target.value,
                                    })
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control border-0 px-1 bg-white"
                                  value={el.quantityApproved}
                                  onKeyDown={(e) =>
                                    e.keyCode === 13 && e.target.blur()
                                  }
                                  onChange={(e) =>
                                    handleInputChange({
                                      index,
                                      name: "quantityApproved",
                                      value: e.target.value,
                                    })
                                  }
                                />
                              </td>
                            </tr>
                          ))}

                        <tr
                          className={`bg-light p-cursor ${activeTableIndex ===
                            -1 && "active-row"}`}
                          /*   onClick={() =>
                          activeTableIndex !== -1 && setActiveTableIndex(-1)
                        } */
                        >
                          <td onClick={(e) => createNew(e)}>
                            <span className="">
                              <MagnifyIcon />
                            </span>
                          </td>
                          <td>
                            <input
                              className="form-control border-0 px-1 bg-white"
                              name="itemName"
                              value={fundRequest.itemName}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && createNewFundRequest(e)
                              }
                              onChange={(e) =>
                                handleFundRequestInput(
                                  e.target.value,
                                  e.target.name
                                )
                              }
                            />
                          </td>{" "}
                          <td>
                            <input
                              className="form-control border-0 px-1 bg-white"
                              name="description"
                              value={fundRequest.description}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && createNewFundRequest(e)
                              }
                              onChange={(e) =>
                                handleFundRequestInput(
                                  e.target.value,
                                  e.target.name
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              className="form-control border-0 px-1 bg-white"
                              name="quantityRequested"
                              value={fundRequest.quantityRequested}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && createNewFundRequest(e)
                              }
                              onChange={(e) =>
                                handleFundRequestInput(
                                  e.target.value,
                                  e.target.name
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              className="form-control border-0 px-1 bg-white"
                              name="availability"
                              value={fundRequest.availability}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && createNewFundRequest(e)
                              }
                              onChange={(e) =>
                                handleFundRequestInput(
                                  e.target.value,
                                  e.target.name
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              className="form-control border-0 px-1 bg-white"
                              name="quantityApproved"
                              value={fundRequest.quantityApproved}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && createNewFundRequest(e)
                              }
                              onChange={(e) =>
                                handleFundRequestInput(
                                  e.target.value,
                                  e.target.name
                                )
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <Form.Group className="col-4 mb-3 pb-2">
                      <Form.Label className="mb-1">
                        Expected Delivery Date
                      </Form.Label>
                      <Datetime
                        dateFormat="MMM DD, YYYY"
                        timeFormat={false}
                        closeOnSelect={true}
                        closeOnClickOutside={true}
                        name="expectedDeliveryDate"
                        inputProps={{
                          className: `date-input form-control ${
                            formik.touched.expectedDeliveryDate &&
                            !!formik.errors.expectedDeliveryDate
                              ? "is-invalid"
                              : ""
                          }`,
                          placeholder: "Select date",
                          readOnly: true,
                        }}
                        value={formik.values.expectedDeliveryDate}
                        onChange={(date) => {
                          formik.setFieldValue(
                            "expectedDeliveryDate",
                            date,
                            true
                          );
                        }}
                        onBlur={() =>
                          formik.setFieldTouched("expectedDeliveryDate", true)
                        }
                      />
                      {formik.touched.expectedDeliveryDate &&
                      !!formik.errors.expectedDeliveryDate ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.expectedDeliveryDate}
                        </span>
                      ) : null}
                    </Form.Group>{" "}
                  </>
                ) : lowerCase(previousFormValues.type) === "inventory" ? (
                  <Table
                    responsive
                    borderless
                    striped
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Item Code</th>
                        <th>Item Name</th>
                        <th>Unit Cost</th>
                        <th>Quantity</th>
                        <th>Subtotal</th>
                      </tr>
                    </thead>
                    <tbody ref={ref}>
                      {tableData &&
                        tableData.map((el, index) => (
                          <tr
                            key={index}
                            className={`${activeTableIndex === index &&
                              "active-row"}`}
                            onClick={() =>
                              activeTableIndex !== index &&
                              setActiveTableIndex(index)
                            }
                          >
                            <td>{index + 1}</td>
                            <td
                              className="p-cursor"
                              onClick={(e) => handleRowClick(e, index)}
                            >
                              {el.barcode}
                            </td>
                            <td>{el.itemName}</td>
                            <td>
                              <CurrencyInput
                                className="form-control border-0 px-1"
                                value={el.unitCost}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                name="unitCost"
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                //    disableGroupSeparators
                                decimalsLimit={2}
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-0 px-1"
                                value={el.quantity}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                name="quantity"
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                //  disableGroupSeparators
                                allowNegativeValue={false}
                                allowDecimals={false}
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-0 px-0"
                                name="subtotal"
                                disabled
                                value={
                                  !isNaN(el.unitCost * el.quantity)
                                    ? currency(el.unitCost, {
                                        precision: 2,
                                      }).multiply(el.quantity)
                                    : 0
                                }
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                // disableGroupSeparators
                                decimalsLimit={2}
                              />
                            </td>
                          </tr>
                        ))}
                      <tr
                        className={`p-cursor ${activeTableIndex === -1 &&
                          "active-row"}`}
                      >
                        <td onClick={(e) => createNew(e)}>
                          <span className="">
                            <MagnifyIcon />
                          </span>
                        </td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                      </tr>
                    </tbody>
                  </Table>
                ) : lowerCase(previousFormValues.type) === "fund request" ||
                  lowerCase(previousFormValues.type) ===
                    "retirement for fund request" ||
                  lowerCase(previousFormValues.type) === "budget" ? (
                  <>
                    {" "}
                    <div className="d-flex gap-3  mb-2">
                      <Form.Group>
                        <Select
                          classNamePrefix="form-select"
                          placeholder="Select Currency"
                          isSearchable={false}
                          options={currenciesOptions}
                          value={currenciesOptions.find(
                            (el) => el.value === formik.values.currency
                          )}
                          onChange={({ value }) =>
                            formik.setFieldValue("currency", value)
                          }
                          isDisabled={
                            lowerCase(previousFormValues.type) ===
                            "retirement for fund request"
                          }
                        />
                      </Form.Group>

                      {formik.values.currency &&
                      formik.values.currency !==
                        generalSettings?.prevailingCurrency ? (
                        <Form.Group>
                          <CurrencyCustomInput
                            currencySymbol={"Ex. Rate"}
                            name="conversionAmount"
                            value={formik.values.conversionAmount}
                            onValueChange={(value, name) => {
                              formik.setFieldValue(name, value);
                            }}
                            placeholder="0.00"
                          />
                        </Form.Group>
                      ) : null}
                    </div>
                    <Table
                      responsive
                      borderless
                      striped
                      className="product-table"
                    >
                      <thead>
                        <tr>
                          <th>S/N</th>
                          <th>Description</th>
                          <th>Unit Cost</th>
                          <th>Quantity</th>
                          <th>Sub Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData &&
                          tableData.map((el, index) => (
                            <tr
                              key={index}
                              className={`${activeTableIndex === index &&
                                "active-row"}`}
                              onClick={() =>
                                activeTableIndex !== index &&
                                setActiveTableIndex(index)
                              }
                            >
                              <td>{index + 1}</td>
                              <td>
                                <input
                                  className="form-control border-0 px-1"
                                  value={el.description}
                                  onKeyDown={(e) =>
                                    e.keyCode === 13 && e.target.blur()
                                  }
                                  onChange={(e) =>
                                    handleInputChange({
                                      index,
                                      name: "description",
                                      value: e.target.value,
                                    })
                                  }
                                />
                              </td>
                              <td>
                                <CurrencyInput
                                  className="form-control border-0 px-1"
                                  value={el.unitCost}
                                  onKeyDown={(e) =>
                                    e.keyCode === 13 && e.target.blur()
                                  }
                                  name="unitCost"
                                  onValueChange={(value, name) =>
                                    handleInputChange({
                                      index,
                                      name,
                                      value,
                                    })
                                  }
                                  //    disableGroupSeparators
                                  decimalsLimit={2}
                                />
                              </td>
                              <td>
                                <CurrencyInput
                                  className="form-control border-0 px-1"
                                  value={el.quantity}
                                  onKeyDown={(e) =>
                                    e.keyCode === 13 && e.target.blur()
                                  }
                                  name="quantity"
                                  onValueChange={(value, name) =>
                                    handleInputChange({
                                      index,
                                      name,
                                      value,
                                    })
                                  }
                                  //  disableGroupSeparators
                                  allowNegativeValue={false}
                                  allowDecimals={false}
                                />
                              </td>
                              <td>
                                <CurrencyInput
                                  className="form-control border-0 px-0"
                                  name="subtotal"
                                  disabled
                                  value={
                                    !isNaN(el.unitCost * el.quantity)
                                      ? currency(el.unitCost, {
                                          precision: 2,
                                        }).multiply(el.quantity)
                                      : 0
                                  }
                                  onKeyDown={(e) =>
                                    e.keyCode === 13 && e.target.blur()
                                  }
                                  onValueChange={(value, name) =>
                                    handleInputChange({
                                      index,
                                      name,
                                      value,
                                    })
                                  }
                                  // disableGroupSeparators
                                  decimalsLimit={2}
                                />
                              </td>
                            </tr>
                          ))}

                        <tr
                          className={`bg-light p-cursor ${activeTableIndex ===
                            -1 && "active-row"}`}
                          onClick={() =>
                            activeTableIndex !== -1 && setActiveTableIndex(-1)
                          }
                        >
                          <td>
                            <label
                              className=""
                              htmlFor="fund-request-description"
                            >
                              {""}
                            </label>
                          </td>
                          <td>
                            <input
                              className="form-control border-0 px-1 bg-white"
                              name="description"
                              id="fund-request-description"
                              value={fundRequest.description}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && createNewFundRequest(e)
                              }
                              onChange={(e) =>
                                handleFundRequestInput(
                                  e.target.value,
                                  e.target.name
                                )
                              }
                            />
                          </td>
                          <td>
                            <CurrencyInput
                              className="form-control border-0 px-1 bg-white"
                              value={fundRequest.unitCost}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && createNewFundRequest(e)
                              }
                              name="unitCost"
                              onValueChange={(value, name) =>
                                handleFundRequestInput(value, name)
                              }
                              //     disableGroupSeparators
                              decimalsLimit={2}
                            />
                          </td>
                          <td>
                            <CurrencyInput
                              className="form-control border-0 px-1 bg-white"
                              value={fundRequest.quantity}
                              name="quantity"
                              onKeyDown={(e) =>
                                e.keyCode === 13 && createNewFundRequest(e)
                              }
                              onValueChange={(value, name) =>
                                handleFundRequestInput(value, name)
                              }
                              //  disableGroupSeparators
                              allowNegativeValue={false}
                              allowDecimals={false}
                            />
                          </td>
                          <td>
                            {fundRequest.unitCost && fundRequest.quantity ? (
                              <CurrencyInput
                                className="form-control border-0 px-0 bg-white"
                                name="subtotal"
                                disabled
                                value={
                                  !isNaN(
                                    fundRequest.unitCost * fundRequest.quantity
                                  )
                                    ? currency(fundRequest.unitCost, {
                                        precision: 2,
                                      }).multiply(fundRequest.quantity)
                                    : ""
                                }
                                //  disableGroupSeparators
                                decimalsLimit={2}
                              />
                            ) : null}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </>
                ) : null}
                <Overlay
                  show={show}
                  onHide={() => setShow(false)}
                  target={target}
                  container={document.body}
                  containerPadding={0}
                  rootClose={true}
                  popperConfig={{
                    strategy: "fixed",
                    modifiers: [
                      {
                        name: "ga-custom",
                        enabled: true,
                        phase: "beforeWrite",
                        fn({ state }) {
                          const oldTransfrom = String(
                            state.styles.popper?.transform
                          );
                          const y = Number(
                            String(oldTransfrom)
                              .split(",")[1]
                              .split(")")[0]
                              .split("px")[0]
                              .trim()
                          );

                          const x = Number(
                            String(oldTransfrom)
                              .split(",")[0]
                              .split("(")[1]
                              .split("px")[0]
                              .trim()
                          );

                          //  console.log(x, y, y < -210);

                          // state.styles.popper.left = `50%`;
                          // state.styles.popper.top = `50%`;
                          state.styles.popper.transform =
                            y < -210
                              ? `translate(${x}px, ${-210}px)`
                              : `translate(${x}px, ${y}px)`;
                        },
                      },
                    ],
                  }}
                >
                  <Popover
                    style={{
                      maxWidth: "unset",
                      height: "28.75rem",
                      overflow: "hidden",
                      boxShadow: "0px 4px 20px 0px #131B2814",
                    }}
                  >
                    <ItemsTable
                      handleSelectedItem={handleSelectedItem}
                      type={
                        lowerCase(previousFormValues.type) !== "inventory"
                          ? previousFormValues.type
                          : ""
                      }
                      getItemFrom={
                        lowerCase(previousFormValues.type) !== "inventory"
                          ? "Equipment"
                          : "Items"
                      }
                    />
                  </Popover>
                </Overlay>
              </div>
              <div className="preview-footer  mt-2">
                {isInventoryOrFundRequest(previousFormValues.type) &&
                lowerCase(previousFormValues.type) !== "material request" ? (
                  <>
                    <p>Total Amount: </p>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        {currencySymbol}
                      </InputGroup.Text>
                      <CurrencyInput
                        placeholder="0.00"
                        className="form-control"
                        // disableGroupSeparators
                        disabled
                        value={total}
                      />
                    </InputGroup>
                  </>
                ) : null}
              </div>

              {lowerCase(previousFormValues.type) === "fund request" && (
                <div className="p-3 my-4 border">
                  <p>If Approved, funds should be paid to</p>
                  <Table borderless responsive className="upsert-table">
                    <tbody>
                      <tr>
                        <td className="fw-bold  text-right">Account Name:</td>
                        <td>
                          <Form.Control
                            placeholder="Enter Account Name"
                            name="requesterAccountName"
                            value={formik.values.requesterAccountName}
                            onChange={formik.handleChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-bold  text-right">Account Number:</td>
                        <td>
                          <Form.Control
                            placeholder="Enter Account Number"
                            name="requesterAccountNumber"
                            value={formik.values.requesterAccountNumber}
                            onChange={formik.handleChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-bold  text-right">Bank:</td>
                        <td>
                          <Form.Control
                            placeholder="Enter Bank"
                            name="requesterBank"
                            value={formik.values.requesterBank}
                            onChange={formik.handleChange}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="px-4 bg-white">
          {![null, -1].includes(activeTableIndex) ? (
            <Button
              disabled={createRequisitionMutation.isLoading}
              onClick={() => removeSelection()}
              variant="danger"
              size="sm"
              className="text-white px-3 "
            >
              Remove Selection
            </Button>
          ) : (
            <span />
          )}

          <div className="proceed-actions">
            <Button
              disabled={createRequisitionMutation.isLoading}
              //  onClick={() => save()}
              variant="primary"
              size="sm"
              type="submit"
              form="requisition-form"
            >
              {createRequisitionMutation.isLoading
                ? "Please wait..."
                : "Send request"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
