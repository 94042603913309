import { Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import currency from "currency.js";
import { useEffect } from "react";
import { Dropdown, Table } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import { isEmpty, lowerCase } from "lodash";
import { useMemo } from "react";
import * as yup from "yup";
import { truncate } from "lodash";

import {
  fetchActionsUtil,
  formatDate,
  pcsToTons,
  qtyFormat,
  qtyFormatToString,
} from "../../utils/helpers";
import { NoSelectedItemIcon, BookIcon, EditIcon, LockIcon } from "../Icons";
import "./../../assets/scss/create-invoice.scss";
import { appSettings } from "../../config";
import SelectBankDialog from "../SelectBankDialog";
import { useCurrencies, useEffectOnce, useIsAdmin } from "../../utils/hooks";
import ConfirmDialog from "../ConfirmDialogue";
import { useAuth } from "../../hooks/useAuth";
import { useStoreState } from "easy-peasy";
import ModalLoader from "../utils/ModalLoader";
import "../../assets/scss/new-entity-modal.scss";
import ConvertQuantity from "../utils/ConvertQuantity";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { Link } from "react-router-dom";

const ViewOrdersPointOfSaleModal = (props) => {
  const taxOptions = [
    {
      label: "None",
      value: "None",
      percentage: 0,
    },
    {
      label: "VAT",
      value: "VAT",
      percentage: 0.05,
    },
    {
      label: "WHT",
      value: "WHT",
      percentage: 0.05,
    },
    {
      label: "NCD",
      value: "NCD",
      percentage: 0.01,
    },
  ];
  const { deploymentCurrencies: currenciesOptions } = useCurrencies();
  const [isLoading, setIsLoading] = useState(false);
  const [isLocking, setIsLocking] = useState(false);
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const defaultCustomer = useStoreState((state) => state.defaultCustomer);
  const generalSettings = useStoreState((state) => state.generalSettings);
  const { customerBackendUrl, backendUrl: currentBackendUrl } = useAuth();
  const backendUrl = useMemo(
    () => (currentBackendUrl ? currentBackendUrl : customerBackendUrl),
    [customerBackendUrl, currentBackendUrl]
  );

  const isAdmin = useIsAdmin();

  const [selectedCustomer, setSelectedCustomer] = useState(defaultCustomer);

  const [tableData, setTableData] = useState([]);
  const [, setLockedTableData] = useState([]);

  const convertQuantity = (Quantity, Item_Desc, type) => {
    return lowerCase(type) === "tons"
      ? pcsToTons(Quantity, Item_Desc, itemMeasurements)
      : Quantity;
  };

  // ----------------------------------------------------------
  const formik = useFormik({
    initialValues: {
      //  tax: "",
      taxType: "None",
      PaymentType: "Proforma",
      proformaNumber: "",
      splitPaymentType: "",
      printWaybill: true,
      salesDate: moment(),
      invoiceCat: "",
      OverwriteOfficer: "Retail",
      chequeNumber: "",
      BankName: "",
      cashAmount: "",
      sendEmail: false,
      printReciept: true,
      dueIn: 1,
      pendingTransaction: "",
      supplyNow: true,
      ShipTo: "",
      customerBalance: "",
      amountToPayFromCustomerCredit: "",
      shippingCost: 0,
      terms: [{ text: "" }],
      remark: "",
      otherCharges: 0,
      currency: "",
      linkedPaymentID: "",
    },
    validationSchema: yup.object().shape({
      PaymentType: yup.string().required(),
      // invoiceCat: yup.string().required("required"),
    }),
    onSubmit: async (values) => {
      if (isEmpty(tableData)) return toast.error(`Please add an Item`);
      if (!selectedCustomer) return toast.error(`Please select a customer`);

      // send to pending
      if (
        await ConfirmDialog({
          title: "Post Transaction",
          description: "Are you sure, you want to make this transaction",
        })
      ) {
      }
    },
    onReset: () => {
      // setTableData([]);
    },
  });

  const showSelectBankDialog = async (props = {}) => {
    return null;
    // const bank = await SelectBankDialog({
    //   ...props,
    //   selectedBank: formik.values.BankName,
    // });
    // if (bank) {
    //   formik.setFieldValue("BankName", bank.bank);
    //   if (props.hasChequeNumber) {
    //     formik.setFieldValue("chequeNumber", bank.chequeNumber);
    //   }
    // }
  };

  useEffect(() => {
    if (
      ["Credit/Debit Card", "Direct Bank Transfer"].includes(
        formik.values.PaymentType
      )
    ) {
      showSelectBankDialog();
    } else if (formik.values.PaymentType === "Cheque") {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    }
    if (formik.values.PaymentType === "Split Payment") {
      formik.setFieldValue("splitPaymentType", "card");
    } else {
      formik.setFieldValue("splitPaymentType", "");
    }
  }, [formik.values.PaymentType]);

  /* Split Payment  */
  useEffect(() => {
    if (["cheque"].includes(formik.values.splitPaymentType)) {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    } else if (
      ["card", "directTransfer"].includes(formik.values.splitPaymentType)
    ) {
      showSelectBankDialog({
        hasChequeNumber: false,
      });
    }
  }, [formik.values.splitPaymentType]);

  const populateTableFromItems = (items) => {
    // convert qtys and clean up

    items = items.map((el) => {
      // check if sales type
      const value = qtyFormat(el.QTY, el.Serial_Number, itemMeasurements).split(
        "-"
      );
      //  console.log(value);
      const tons = value[0];
      const pcss = value[1];
      const istype = value[0];

      let theSaleType = el.Serial_Number
        ? Number(tons) > 0
          ? "Tons"
          : "Pieces"
        : "Each";

      if (istype === "normal") {
        theSaleType = "Each";
      }

      // console.log(theSaleType);

      return {
        ...el,
        saleType:
          appSettings.requireSalesRep || appSettings.isBatchStandard
            ? "Each"
            : theSaleType,
        Item_Desc: el.Serial_Number,
        PriceSold: currency(el.PriceSold, {
          symbol: "",
          separator: "",
        }).format(),
        Quantity: currency(el.QTY, {
          symbol: "",
          separator: "",
        }).format(),
        UnitCost: currency(el.UnitCost, {
          symbol: "",
          separator: "",
        }).format(),
        Discount: currency(el.Discount, {
          symbol: "",
          separator: "",
        }).format(),
        SubTotal: currency(el.SubTotal, {
          symbol: "",
          separator: "",
        }).format(),
        Profit: currency(el.Profit, {
          symbol: "",
          separator: "",
        }).format(),
        UnitPrice: currency(el.Unit_Price, {
          symbol: "",
          separator: "",
        }).format(),
      };
    });

    //console.log(items);

    if (items) {
      formik.resetForm();

      const {
        PayType,
        VAT,
        OverwriteOfficer,
        Date_Log,
        TransactionID,
        customer,
        ProductName,
        shippingCost,
        currency,
        otherCharges,
        terms,
        remark,
        taxType,
        ShipTo,
        linkedPaymentID,
      } = items[0];
      formik.setValues({
        ...formik.values,
        taxValue: VAT,
        PaymentType: PayType,
        OverwriteOfficer,
        salesDate: Date_Log,
        pendingTransaction: TransactionID,
        invoiceCat: ProductName,
        shippingCost,
        currency: currency || generalSettings?.prevailingCurrency,
        otherCharges,
        terms: terms ? JSON.parse(terms) : [{ text: "" }],
        remark,
        taxType: taxType || "None",
      });

      // This was not setting, had to delay
      setTimeout(() => {
        formik.setFieldValue("ShipTo", ShipTo);
        formik.setFieldValue("linkedPaymentID", linkedPaymentID);
      }, 500);

      setTableData(items);
      setSelectedCustomer(customer);

      // locked
      setLockedTableData(items);
    }
  };

  useEffect(() => {
    formik.setFieldValue(
      "ShipTo",
      selectedCustomer?.LastName
        ? selectedCustomer?.LastName
        : defaultCustomer.LastName
    );
  }, [selectedCustomer]);

  // discount sum
  const discount = useMemo(() => {
    const sum = tableData
      ? tableData
          .map(
            (el) => el.Discount
            /*  currency(el.Discount, { symbol: "", separator: "" })
                .multiply(
                  convertQuantity(el.Quantity, el.Serial_Number, el.saleType)
                )
                .format() */
          )
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;
    return sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        }).format()
      : "0.00";
  }, [tableData]);

  const subTotal = useMemo(() => {
    const sum = tableData
      ? tableData
          .map((el) => el.SubTotal)
          .reduce(
            (a, b) =>
              currency(a, {
                symbol: "",
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;
    return sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        })
          .add(discount)
          .format()
      : "0.00";
  }, [tableData, discount]);

  const chargesAfterTax = useMemo(() => {
    return currency(formik.values.loadingCharge, {
      symbol: "",
      separator: "",
    })
      .add(formik.values.offloadingCharge)
      .add(formik.values.posCharge)
      .add(formik.values.transportCharge)
      .add(formik.values.shippingCost)
      .add(formik.values.otherCharges);
  }, [
    formik.values.offloadingCharge,
    formik.values.loadingCharge,
    formik.values.posCharge,
    formik.values.transportCharge,
    formik.values.shippingCost,
    formik.values.otherCharges,
  ]);

  const taxValue = useMemo(() => {
    const taxType = taxOptions.find((el) => el.value === formik.values.taxType);

    return currency(subTotal, {
      symbol: "",
      separator: "",
    })
      .multiply(taxType?.percentage)
      .format();
  }, [subTotal, formik.values.taxType]);

  const amountDue = useMemo(() => {
    const sum = tableData
      ? tableData
          .map((el) => el.SubTotal)
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;

    const total = sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        })
          .multiply(100)
          .divide(100)
          .add(taxValue)
          .add(chargesAfterTax)
          .format()
      : "0.00";

    const value = formik.values.PaymentType;
    if (value === "Credit" || value === "Customer Account") {
      formik.setFieldValue("amountPaid", 0);
    } else {
      formik.setFieldValue("amountPaid", total);
    }

    formik.setFieldValue("amountToPayFromCustomerCredit", total);
    formik.setFieldValue("cashAmount", total);
    return total;
  }, [tableData, taxValue, chargesAfterTax]);

  const amount = useMemo(() => {
    return currency(amountDue, {
      symbol: "",
      separator: "",
    })
      .subtract(formik.values.cashAmount)
      .format();
  }, [amountDue, formik.values.cashAmount]);

  const balance = useMemo(() => {
    // is Balance Zero for split Payment
    const totalCashForSplit = currency(formik.values.cashAmount, {
      symbol: "",
      separator: "",
    })
      .add(amount)
      .format();

    return currency(amountDue, {
      symbol: "",
      separator: "",
    })
      .subtract(
        formik.values.PaymentType === "Split Payment"
          ? totalCashForSplit
          : formik.values.amountPaid
      )
      .format();
  }, [
    amount,
    amountDue,
    formik.values.amountPaid,
    formik.values.PaymentType,
    formik.values.cashAmount,
  ]);

  const grandTotal = useMemo(() => {
    return amountDue;
  }, [amountDue]);

  const getPendingItems = async (TransactionID) => {
    try {
      setIsLoading(true);
      let response = await fetch(
        `${backendUrl}/api/permits/get-by-transaction/${TransactionID}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        toast.error(response.message);
      } else {
        const {
          data: { items = [] },
        } = await response.json();
        if (isEmpty(items)) {
          return toast.error("No Items found");
        }

        populateTableFromItems(items);
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to get Items, Try again");
    } finally {
      setIsLoading(false);
    }
  };

  useEffectOnce(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    getPendingItems(props?.TransactionID);
  });

  const openProformaInvoiceUrl = ({ thermalPrinter }) => {
    return !isEmpty(tableData) && tableData[0]?.Status !== "Quotation"
      ? `${backendUrl}/api/invoice/pdf/proforma-invoice/${
          props?.TransactionID
        }?thermalPrinter=${thermalPrinter}`
      : `${backendUrl}/api/invoice/pdf/quotation/${
          props?.TransactionID
        }?thermalPrinter=${thermalPrinter}`;
  };

  const currencySymbol = useMemo(() => {
    const foundCurrency = currenciesOptions.find(
      (el) => el.cc === formik.values.currency
    );
    return foundCurrency ? foundCurrency.symbol : "";
  }, [formik.values.currency]);

  const lockItemFromBeenSold = async ({ isLocked, Bar_Code }) => {
    try {
      setIsLocking(true);
      const res = await fetchActionsUtil(
        `${backendUrl}/api/permits/lock`,
        "POST",
        "",
        {
          isLocked,
          Bar_Code,
          TransactionID: props.TransactionID,
        }
      );
      setTableData((oldData) =>
        oldData.map((el) =>
          el.Bar_Code === Bar_Code
            ? { ...el, lockItemFromBeenSold: isLocked }
            : { ...el }
        )
      );
      toast.success(res?.message);
    } catch (err) {
      console.log(err);
      toast.error("Check connection and Try again");
    } finally {
      setIsLocking(false);
    }
  };

  return (
    <>
      <Modal
        show={props?.show}
        onHide={() => props?.onHide()}
        dialogClassName="small-modal"
        backdropClassName={`global-backdrop`}
        size="xl"
        centered={true}
        animation={false}
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>Order Details </h1>
            {/* <p>Login by filling in the following forms.</p> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <main className="create-invoice">
            <div className="p-0 content">
              <div className="d-md-flex content-holder rounded">
                <section className="item-details item-details-override p-0">
                  <div>
                    {/* <header className="d-flex justify-content-between my-3">
                      <h1> #{props?.TransactionID}</h1>
                      <div className="d-flex justify-content-end align-items-baseline gap-3">
                        <Form.Group>
                          <Select
                            classNamePrefix="form-select"
                            placeholder="Select Currency"
                            isSearchable={false}
                            options={currenciesOptions}
                            value={currenciesOptions.find(
                              (el) => el.value === formik.values.currency
                            )}
                            onChange={({ value }) =>
                              formik.setFieldValue("currency", value)
                            }
                          />
                        </Form.Group>

                        <Dropdown className="">
                          <Dropdown.Toggle>View Invoice</Dropdown.Toggle>

                          <Dropdown.Menu className="dropdown-with-icons">
                            <Dropdown.Item
                              href={openProformaInvoiceUrl({
                                thermalPrinter: false,
                              })}
                              target="blank"
                            >
                              <BookIcon />
                              A4 Size Printer
                            </Dropdown.Item>
                            <Dropdown.Item
                              href={openProformaInvoiceUrl({
                                thermalPrinter: true,
                              })}
                              target="blank"
                            >
                              <BookIcon />
                              Thermal Printer
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </header> */}

                    <div className="selected-data-area">
                      <div className="table-holder">
                        <Table
                          responsive
                          borderless
                          hover
                          striped
                          className="product-table text-nowrap"
                        >
                          <thead>
                            <tr>
                              <th>Item Name</th>
                              <th>Quantity</th>
                              <th>Price Sold</th>
                              <th>Discount</th>
                              <th>Subtotal</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableData
                              .filter((el) =>
                                lowerCase(el?.Cat_Name).includes("food")
                              )
                              .map((el, index) => (
                                <tr key={index} className="p-cursor">
                                  <td>{el.Item_Name} </td>
                                  <td title={el.Quantity}>
                                    <ConvertQuantity
                                      quantity={el.Quantity}
                                      desc={el?.Item_Desc}
                                      convertNow={true}
                                    />
                                  </td>{" "}
                                  <td>
                                    {currency(el.PriceSold, {
                                      symbol: "",
                                    }).format()}
                                  </td>
                                  <td>
                                    {currency(el.Discount, {
                                      symbol: "",
                                    }).format()}
                                  </td>
                                  <td>
                                    {currency(el.SubTotal, {
                                      symbol: "",
                                    }).format()}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </div>
                      {/*  */}
                      {/*  No item  */}
                      {isEmpty(tableData) && !isLoading ? (
                        <div className="no-item my-4">
                          <div className="info">
                            <NoSelectedItemIcon />
                            <h2 className="mb-2">
                              Haven't selected an item yet
                            </h2>
                          </div>
                        </div>
                      ) : null}{" "}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </main>
        </Modal.Body>
      </Modal>
      <ModalLoader show={isLoading} />
    </>
  );
};

export default ViewOrdersPointOfSaleModal;
