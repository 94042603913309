import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import { useStoreState } from "easy-peasy";

function ItemExchangeRateModal({
  showItemExchangeRateModal,
  setShowItemExchangeRateModal,
  refetch,
}) {
  const generalSettings = useStoreState((state) => state?.generalSettings);
  const { backendUrl } = useAuth();

  const addConversion = async (payload) => {
    let response = await fetch(`${backendUrl}/api/items/convert-currency`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        ...payload,
      }),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const formik = useFormik({
    initialValues: { conversionAmount: generalSettings.dollarInBaseCurrency },
    onSubmit: (values) => {
      addConversionMutation.mutate(values);
    },
  });

  const addConversionMutation = useMutation(
    (payload) => addConversion(payload),
    {
      onError: () => {
        toast.error(`Unable to perform action`);
      },
      onSuccess: () => {
        toast.success(`Success`);
        refetch();
        setShowItemExchangeRateModal(false);
      },
    }
  );

  return (
    <>
      <Modal
        show={showItemExchangeRateModal}
        onHide={setShowItemExchangeRateModal}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">Exchange Rate</Modal.Title>
        </Modal.Header>
        <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>
                1 USD → {generalSettings?.prevailingCurrency}:
              </Form.Label>
              <CurrencyCustomInput
                id="conversionAmount"
                name="conversionAmount"
                value={formik.values.conversionAmount}
                onValueChange={(value, name) =>
                  formik.setFieldValue(name, value)
                }
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              type="button"
              nClick={() => setShowItemExchangeRateModal(false)}
            >
              Close
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={addConversionMutation.isLoading}
            >
              {addConversionMutation?.isLoading
                ? "Please wait..."
                : " Save Changes"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default ItemExchangeRateModal;
