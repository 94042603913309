import React, { useState, useEffect, useRef, useCallback } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import "./styles.css";
import { Button } from "react-bootstrap";
import { SummaryCloseIcon } from "../Icons";
import Draggable from "react-draggable";
import { useLocalStorage } from "./../../utils/hooks";
// import { usePopper } from "react-popper";

// import { usePopover } from "react-tiny-popover";
// import { OverlayTrigger,  Popover } from "react-bootstrap";

// const virtualReference = {
//   getBoundingClientRect() {
//     return {
//       top: 10,
//       left: 10,
//       bottom: 20,
//       right: 100,
//       width: 90,
//       height: 10,
//     };
//   },
// };

export default function VirtualKeyboard({ children, filter }) {
  const [layout, setLayout] = useState("default");
  const keyboard = useRef();
  const [inputElement, setInputElement] = useState(null);

  const [showCloseBtn, setCloseBtutton] = useState(
    Boolean(/electron/i.test(navigator.userAgent))
  );

  const [showKeyboard, setShowKeyboard] = useLocalStorage(
    "showKeyboard",
    Boolean(/electron/i.test(navigator.userAgent))
  );

  // const [popperElement, setPopperElement] = useState(null);
  // const [elementPosition, setElementPostion] = useState({ x: 0, y: 0 });
  // const [initialElementPosition, setInpitialElementPosition] = useState(
  //   virtualReference
  // );

  /*   const { styles, attributes } = usePopper(
    // initialElementPosition,
    inputElement,
    popperElement,
    {
      placement: "auto", //preferred placement of popper
      modifiers: [
        {
          name: "offset", //offsets popper from the reference/button
          options: {
            offset: [0, 8],
            rootBoundary: "viewport",
          },
        },
        {
          name: "flip", //flips popper with allowed placements
          options: {
            allowedAutoPlacements: ["right", "left", "top", "bottom"],
            rootBoundary: "viewport",
          },
        },
      ],
    }
  ); */

  // const { styles, attributes } = usePopper(virtualReference, popperElement, {
  //   placement: "auto", //preferred placement of popper
  //   modifiers: [
  //     {
  //       name: "offset", //offsets popper from the reference/button
  //       options: {
  //         offset: [0, 8],
  //       },
  //     },
  //     {
  //       name: "flip", //flips popper with allowed placements
  //       options: {
  //         allowedAutoPlacements: ["right", "left", "top", "bottom"],
  //         rootBoundary: "viewport",
  //       },
  //     },
  //   ],
  // });

  // const onChange = (input) => {
  //   inputElement.value = input;

  // };

  const onChange = (input) => {
    console.log(input);
    if (!Boolean(inputElement)) {
      return;
    }

    var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      "value"
    ).set;
    nativeInputValueSetter.call(inputElement, input);

    var inputEvent = new Event("input", { bubbles: true });
    inputEvent.simulated = true;
    inputElement.dispatchEvent(inputEvent);
    inputElement.focus();
  };

  const handleShift = (button) => {
    if (button === "{lock}") {
      setLayout("caps");
    } else if (button === "{shift}") {
      const newLayoutName = layout === "default" ? "shift" : "default";
      setLayout(newLayoutName);
    } else if (button === "{low}") {
      setLayout("shift");
    }
  };

  const onKeyPress = (button) => {
    if (button === "{shift}" || "{caps}|| '{low}' ") handleShift(button);
  };

  const globalFocus = () => {
    document.addEventListener("focusin", handleFocus);
    // document.addEventListener("blur", handleBlur);
  };
  const handleInput = (event) => {
    const input = event.target.value;
    keyboard.current.setInput(input);
  };

  const handleFocus = (event) => {
    setCloseBtutton(false);

    // console.log(event?.target.id);

    const targetId = event?.target.id || "";

    if (
      (event?.target?.nodeName === "INPUT" ||
        event?.target?.nodeName === "TEXTAREA") &&
      !targetId.includes("react-select")
    ) {
      event.preventDefault();

      if (
        event?.target?.type === "password" ||
        event?.target?.inputMode === "decimal" ||
        event?.target?.type === "number"
      ) {
        setLayout("default");
        setCloseBtutton(true);
      } else if (event?.target.type === "text" || "textarea") {
        setLayout("shift");
        setCloseBtutton(true);
      }

      setInputElement(event.target);

      if (inputElement?.type === "email" || inputElement?.type === "text") {
        setLayout("shift");
        setCloseBtutton(true);
      }

      keyboard.current.setInput(event.target.value);
      event.target.addEventListener("change", handleInput);
      // const computedStyle = window.getComputedStyle(event.target);
      // const width = computedStyle.getPropertyValue("width");
      // const height = computedStyle.getPropertyValue("height");

      // const rect = event.target.getBoundingClientRect();

      // setInpitialElementPosition({
      //   getBoundingClientRect() {
      //     return {
      //       ...initialElementPosition,
      //       top: rect.top,
      //       left: rect.left,
      //       bottom: rect.bottom,
      //       right: rect.right,
      //       width: width,
      //       height: height,
      //     };
      //   },
      // });

      // const offsetX = 0; // Adjust this value as needed
      // const offsetY = 0; // Adjust this value as needed

      // let clientBindX = rect.left + offsetX;
      // let clientBindY = rect.top + offsetY;
      // const viewportWidth =
      //   window.innerWidth || document.documentElement.clientWidth;
      // const viewportHeight =
      //   window.innerHeight || document.documentElement.clientHeight;

      // const elementWidth = parseInt(width) + Number(clientBindX);
      // const elementHeight = parseInt(height) + Number(clientBindY);

      // event.target.ref = inputElementRef;

      // if (
      //   elementWidth * 2 < viewportWidth &&
      //   elementHeight > viewportHeight / 2
      // ) {
      //   clientBindX = elementWidth + width;
      //   clientBindY = elementHeight - height * 2;
      // }
      // if (
      //   (rect.top * 2 > viewportHeight && rect.right * 2 < viewportWidth) /
      //   viewportHeight
      // ) {
      //   clientBindY = 50;
      //   clientBindX = 880;
      // } else if (
      //   rect.right * 2 > viewportWidth &&
      //   rect.top * 2 < viewportHeight
      // ) {
      //   clientBindX = 20;
      //   clientBindY = 80;
      // } else if (
      //   rect.right * 2 > viewportWidth &&
      //   rect.top * 2 > viewportHeight
      // ) {
      //   clientBindX = 0;
      //   clientBindY = 0;
      // } else if (
      //   (rect.top * 2 > viewportHeight && rect.right * 2 > viewportWidth) /
      //   viewportHeight
      // ) {
      //   clientBindY = 0;
      //   clientBindX = 0;
      // }

      // if (Number(clientBindY) * 2 > viewportHeight) {
      //   clientBindY = -10;
      // }
      // if (Number(clientBindX) * 2 > viewportWidth) {
      //   clientBindX = -20;
      // }

      // setElementPostion({ ...elementPosition, x: clientBindX, y: clientBindY });
    } else {
      setCloseBtutton(false);
    }
  };

  useEffect(() => {
    globalFocus();

    return () => {
      document.removeEventListener("focusin", handleFocus);
      document.removeEventListener("change", handleInput);
    };
  }, []);

  const disableKeyboard = (event) => {
    if (event.ctrlKey && event.key === "b") {
      setShowKeyboard(!showKeyboard);

      event.preventDefault();
    }
  };

  useEffect(() => {
    // Add event listener when component mounts
    document.addEventListener("keydown", disableKeyboard);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("keydown", disableKeyboard);
    };
  }, [showKeyboard]);

  function handleDisableKeyboardBtn() {
    setShowKeyboard(!showKeyboard);
  }

  function handleCloseBtn() {
    if (inputElement) {
      setCloseBtutton(false);
    }
  }

  function handleMouseDown(e) {
    e.preventDefault();
  }

  return (
    <Draggable
      onMouseDown={handleMouseDown}
      positionOffset={{ x: "-50%", y: "0%" }}
    >
      <div
        className={`kyboard ${
          showCloseBtn && !showKeyboard ? " d-block" : "d-none"
        } position-fixed `}
        // ref={setPopperElement}
        // style={styles.popper}
        // {...attributes.popper}

        // ref={setPopperElement}
        // style={styles.popper}
        // {...attributes.popper}
        style={{}}
      >
        <div className="d-flex  justify-content-end w-100  align-content-center">
          {!showKeyboard && showCloseBtn && (
            <Button
              className=" btn btn-sm close-button my-2"
              variant="outline-primary"
              onClick={handleDisableKeyboardBtn}
            >
              Disable Keyboard
            </Button>
          )}
          <Button
            title="Remove"
            variant=""
            type="button"
            className=" close-button"
            onClick={handleCloseBtn}
          >
            <SummaryCloseIcon />
          </Button>
        </div>
        <Keyboard
          keyboardRef={(r) => (keyboard.current = r)}
          layoutName={layout}
          onChange={onChange}
          onKeyPress={onKeyPress}
          layout={{
            default: [
              "1 2 3",
              "4 5 6",
              "7 8 9",
              "* 0 #",
              "+ - /",
              "= {shift} {enter} {space} {bksp}",
              // "1 2 3 4 5",
              // "6 7 8 9 0",
              // "+ - * / =",
              // " {shift} {enter} {bksp} {space}",
              ,
            ],
            shift: [
              //   "! @ # $ % {bksp}",
              //   '" " &amp; ( ) ~',
              //   "a b c d e f",
              //   "g h i j k l",
              //   "m n o p q r",
              //   "s t u v w x",
              //   "y z {space} {shift} {lock}",

              "q w e r t y u i o p",
              "a s d f g h j k l ; '",
              "z x c v b n m , . /",
              ".com @ {space} {shift} {lock} {bksp}",
              // "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
              // "{tab} q w e r t y u i o p [ ] \\",
              // "{lock} a s d f g h j k l ; ' {enter}",
              // "{shift} z x c v b n m , . / {shift}",
              // ".com @ {space}",
            ],
            caps: [
              // "! @ # $ % {bksp}",
              // '" " &amp; ( ) ~',
              // "A B C D E F",
              // "G H I J K L",
              // "M N O P Q R",
              // "S T U V W X",
              // "Y Z {space} {shift} low",
              "Q W E R T Y U I O P",
              "A S D F G H J K L ; '",
              "Z X C V B N M",
              ".com @ {space} {shift} {low} {bksp}",
              // "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
              // "{tab} Q W E R T Y U I O P [ ] \\",
              // "{low} A S D F G K J K L ; ' {enter}",
              // "{shift} Z X C V B N M , . / {shift}",
              // ".com @ {space}",
            ],
          }}
          display={{
            "{bksp}": "backspace",
            "{enter}": "< enter",
            "{tab}": "tab",
            "{lock}": "caps",
            "{shift}": "shift",
            "{space}": "  ",
            "{low}": "caps",
            "*": "X",
          }}
          physicalKeyboardHighlightTextColor={"blue"}
        />
      </div>
    </Draggable>
  );
}

// import React, { useEffect, useRef, useState } from "react";
// import Keyboard from "simple-keyboard";
// import "simple-keyboard/build/css/index.css";
// import "./index.css";

// const VirtualKeyboard = () => {
//   let keyboard = new Keyboard({
//     onChange: (input) => onChange(input),
//     onKeyPress: (button) => onKeyPress(button),
//     layout: {
//       default: [
//         "1 2 3",
//         "4 5 6",
//         "7 8 9",
//         "0 + -",
//         "= * /",
//         "{shift} {space} {bksp}",
//       ],

//       shift: [
//         "a b c d",
//         "e f g h",
//         "i j k l",
//         "m n o p",
//         "q r s t",
//         "u v w x",
//         "y z .",
//         "{shift}  {bksp}",
//       ],
//     },
//     theme: "hg-theme-default hg-layout-numeric numeric-theme",
//   });

//   let capsLockEnabled = false;

//   function onChange(input) {
//     document.querySelector(".input").value = input;
//     console.log("Input changed", input);
//   }

//   function onKeyPress(button) {
//     console.log("Button pressed", button);

//     if (button === "{shift}" || button === "{lock}") {
//       handleShift();
//     } else if (button === "{capslock}") {
//       handleCapsLock();
//     } else if (button === "abc") {
//       handleAlphabetic();
//     }
//   }

//   function handleShift() {
//     let currentLayout = keyboard.options.layoutName;
//     let shiftToggle = currentLayout === "default" ? "shift" : "default";

//     keyboard.setOptions({
//       layoutName: shiftToggle,
//     });
//   }

//   function handleCapsLock() {
//     capsLockEnabled = !capsLockEnabled;
//     let currentLayout = capsLockEnabled ? "shift" : "default";

//     keyboard.setOptions({
//       layoutName: currentLayout,
//     });
//   }

//   function handleAlphabetic() {
//     let currentLayout = keyboard.options.layoutName;
//     let alphabeticToggle =
//       currentLayout === "default" ? "alphabetic" : "default";

//     keyboard.setOptions({
//       layoutName: alphabeticToggle,
//     });
//   }

//   /**
//    * Update simple-keyboard when input is changed directly
//    */
//   document.querySelector(".input").addEventListener("input", (event) => {
//     keyboard.setInput(event.target.value);
//   });

//   return keyboard;
// };

// export default VirtualKeyboard;
