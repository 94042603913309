import { Dropdown, Table, Form, Button } from "react-bootstrap";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  ChartPieSliceTitleIcon,
  FilterCollapseIcon,
  ExchangeFunds,
  DropdownEyeIcon,
  DropdownCheckIcon,
  DropdownCloseIcon,
  LinkVariantIcon,
  EditIcon,
  DeleteIcon,
  RecieptIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { services } from "../../config";
import { reportActions } from "../../utils/reactQueryActions";
import { useMutation, useQuery, useQueryClient } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  copyText,
  customerFullName,
  defaultSelectValue,
  fetchActionsUtil,
  formDataForFormBuider,
  paginationOptions,
  sendFormData,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import PageHeader from "../PageHeader";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Outlet, useNavigate } from "react-router-dom";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import { LinkWithQuery as Link } from "../utils/LinkWithQuery";
import { toast } from "react-toastify";
import eventBus from "../../utils/EventBus";
import { filter, isEmpty, uniq } from "lodash";
import { useAuth } from "../../hooks/useAuth";
import ConfirmDialog from "../ConfirmDialogue";
import ModalLoader from "../utils/ModalLoader";
import NoTableItem from "../utils/NoTableItem";
import UpdateItemModal from "../UpdateItemModal";
import GRNDialog from "../GRNDialog";
import EditPurchaseHistoryModal from "../EditPurchaseHistory";
import moment from "moment";
import EditWarehousePurchaseHistoryModal from "../warehouse/EditWarehousePurchaseHistory";
import ViewPurchaseOrderModal from "../ViewPurchaseOrder";
import ViewImageModal from "../ViewImageModal";
import { SingleRFQTableModal } from "../SingleRFQTable";
import { useStoreState } from "easy-peasy";

const options = [
  { label: "Pending", value: "Pending" },
  {
    label: "Approved",
    value: "Approved",
  } /* 
  { label: "Invoiced", value: "Invoiced" }, */,
  { label: "Disapproved", value: "Disapproved" },
];

export default function ApproveVendorInvoice() {
  //  useScrollTop();
  const { backendUrl } = useAuth();
  const queryClient = useQueryClient();
  const initialFilterParams = { q: "", page: 1, limit: 100, Status: "" };

  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useQueryParams({});
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);
  const [selectedItems, setSelectedItems] = useLocalStorage(
    "SELECTED_PENDING_INVENTORY_ACTIONS_ID",
    []
  );
  const [showUpdateInventoryModal, setShowUpdateInventoryModal] = useState(
    false
  );

  const generalSettings = useStoreState((state) => state.generalSettings);

  const [
    showEditPurchaseHistoryModal,
    setShowEditPurchaseHistoryModal,
  ] = useState(false);

  const [
    showEditPurchaseHistoryModalWH,
    setShowEditPurchaseHistoryModalWH,
  ] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [purchaseHistoryToEdit, setPurchaseHistoryToEdit] = useState([]);
  const [selectedRFQ, setSelectedRFQ] = useState({});

  const [dueDate, setDueDate] = useState("");

  const [showCreateQuoteModal, setShowCreateQuoteModal] = useState(false);
  const [imageName, setImageName] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const [showImage, setShowImage] = useState(false);
  const [childFormId, setChildFormId] = useState("");
  const [selectedFilledForm, setSelectedFilledForm] = useState({});
  const [openViewPO, setOpenViewPO] = useState(false);

  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const fetchPendingActions = async (queryParams) => {
    // console.log(queryParams);
    let response = await fetch(
      `${backendUrl}/api/permits/pending-inventory-action/all?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };

  const {
    data = { pendingInventoryActions: [] },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    ["PENDING_INVENTORY_ACTIONS", queryParams],
    () => fetchPendingActions(queryParams),
    {
      keepPreviousData: true,
      staleTime: 0,
    }
  );

  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.value,
    });
  };

  const viewDetails = (el) => {
    if (el.Status === "Approved") {
      window.open(
        `${backendUrl}/api/reports/pdf/grn/${el.InvoiceNo}`,
        "_blank",
        "noopener,noreferrer"
      );
      return;
    }
    if (el.Type === "STOCK INBOUND") {
      editPurchase(el);
    } else if (el.Type === "WAREHOUSE STOCK INBOUND") {
      const Warehouse = JSON.parse(el?.DataToPost)?.stockItems[0]?.Warehouse;
      el.Warehouse = Warehouse;
      editPurchaseWarehouse(el);
    }
  };

  const updateAllPermit = async (payload) => {
    let response = await fetch(
      `${backendUrl}/api/permits/pending-inventory-action/change-status-all`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const updatePermitsMutation = useMutation(
    (payload) => updateAllPermit(payload),
    {
      onSuccess: ({ status, transactionIds }) => {
        refetch();
        toast.success(`Success`);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const deletePermitApi = async (payload) => {
    let response = await fetch(
      `${backendUrl}/api/permits/pending-inventory-action/delete`,
      {
        method: "POST",
        // credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const deletePermitMutation = useMutation(
    (payload) => deletePermitApi(payload),
    {
      onSuccess: ({ status, transactionIds }) => {
        queryClient.setQueryData(
          ["PENDING_INVENTORY_ACTIONS", queryParams],
          (data) => {
            data.pendingInventoryActions = data.pendingInventoryActions.filter(
              (el) => !transactionIds.includes(el.id)
            );

            return data;
          }
        );
        toast.success(`Success`);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const approveAll = async () => {
    if (isEmpty(selectedItems)) {
      return toast.error("Please select");
    }
    if (
      await ConfirmDialog({
        title: "Approve Selected",
        description: "Are you sure, you want to approve selected",
      })
    ) {
      const transactionIds = selectedItems.map((el) => el);
      updatePermitsMutation.mutate(
        {
          status: "Approved",
          transactionIds,
        },
        {
          onSuccess: () => {
            setSelectedItems([]);
          },
        }
      );
    }
  };

  const disapproveAll = async () => {
    if (
      await ConfirmDialog({
        title: "Disapprove all",
        description: "Are you sure, you want to disapprove all",
      })
    ) {
      const transactionIds = data.pendingInventoryActions.map((el) => el.id);
      updatePermitsMutation.mutate({
        status: "Disapproved",
        transactionIds,
      });
    }
  };

  const approve = async (el) => {
    if (
      await ConfirmDialog({
        title: "Approve",
        description: "Are you sure, you want to approve",
      })
    ) {
      updatePermitsMutation.mutate({
        status: "Approved",
        transactionIds: [el.id],
      });
    }
  };

  const disapprove = async (el) => {
    if (
      await ConfirmDialog({
        title: "Disapprove",
        description: "Are you sure, you want to disapprove",
      })
    ) {
      updatePermitsMutation.mutate({
        status: "Disapproved",
        transactionIds: [el.id],
      });
    }
  };

  const deletePermit = async (el) => {
    if (
      await ConfirmDialog({
        title: "Delete",
        description: "Are you sure, you want to delete",
      })
    ) {
      deletePermitMutation.mutate({
        transactionIds: [el.id],
      });
    }
  };

  /* eventBus.useCustomEventListener(
    eventBus.APPROVE_ALL_PERFORMA_INVOICE,
    disapproveAll
  );

  eventBus.useCustomEventListener(
    eventBus.DISAPPROVE_ALL_PERFORMA_INVOICE,
    approveAll
  ); */

  eventBus.useCustomEventListener("FETCH_PENDING_PERMITS", refetch);

  // const editProforma = (TransactionID) => {};

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const handleSelectedItem = (id, checked) => {
    if (checked) {
      setSelectedItems(uniq([...selectedItems, id]));
    } else {
      setSelectedItems(uniq([...selectedItems.filter((el) => el !== id)]));
    }
  };

  const printGRN = async (InvoiceNo) => {
    await GRNDialog({
      title: "Transaction posted successfully",
      // description: "...",
      InvoiceNo,
    });
  };

  const getPurchase = async ({ InvoiceNo, id }) => {
    try {
      setIsLoading(true);
      let response = await fetch(
        `${backendUrl}/api/vendors/purchase/${InvoiceNo}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        throw new Error();
      } else {
        let {
          data: { purchasedItems },
        } = await response.json();
        if (isEmpty(purchasedItems)) {
          toast.error("Purchase not found");
          return;
        }
        setPurchaseHistoryToEdit(
          purchasedItems.map((el) => ({
            ...el.item,
            ...el,
            UnitPrice: el.item.UnitPrice,
            PurchasePrice: el.UnitPrice,
            Item_Name: el.ItemName,
            vendorName: el.vendor?.CompanyName,
            Unit: el.saleType,
            InvoiceAmount: el.AmountDue,
            Vendor: el.vendor.Vendor_ID,
            selectedBatches: [el.purchaseBatchItem],
            batchHasBeenSold:
              Number(el.purchaseBatchItem.originalQuantity) >
              Number(el.purchaseBatchItem.Quantity),
            // ---------------------------------------------------------
            Batch_Name: el.purchaseBatchItem.Batch_Name,
            parentBatchBarCode: el.purchaseBatchItem.parentBatchItemName,
            parentBatchItemName: el.purchaseBatchItem.parentBatchItemName,
            parentBatchName: el.purchaseBatchItem.parentBatchName,
            DriverName: el.purchaseBatchItem.DriverName,
            DriverPhoneNumber: el.purchaseBatchItem.DriverPhoneNumber,
            TruckNumber: el.purchaseBatchItem.TruckNumber,
            DeliveryDate: moment(new Date(el.purchaseBatchItem.DeliveryDate)),
            ManDate: moment(new Date(el.purchaseBatchItem.ManDate)),
            ExpireDate: moment(new Date(el.purchaseBatchItem.ExpireDate)),
            pendingId: id,
            totalLandingCost: currency(el.UnitPrice).multiply(el.Quantity)
              .value,
          }))
        );
        setShowEditPurchaseHistoryModal(true);
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to load Purchase, Try again");
    } finally {
      setIsLoading(false);
    }
  };

  const getPurchaseWH = async ({ InvoiceNo, id, Warehouse }) => {
    try {
      setIsLoading(true);
      let response = await fetch(
        `${backendUrl}/api/warehouse/get-purchase/${InvoiceNo}/${Warehouse}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        throw new Error();
      } else {
        let {
          data: { purchasedItems },
        } = await response.json();
        if (isEmpty(purchasedItems)) {
          toast.error("Purchase not found");
          return;
        }
        setPurchaseHistoryToEdit(
          purchasedItems.map((el) => ({
            ...el.item,
            ...el,
            UnitPrice: el.item.UnitPrice,
            PurchasePrice: el.UnitPrice,
            Item_Name: el.ItemName,
            vendorName: el.vendor?.CompanyName,
            Unit: el.saleType,
            InvoiceAmount: el.AmountDue,
            Vendor: el.vendor.Vendor_ID,
            selectedBatches: [el.purchaseBatchItem],
            batchHasBeenSold:
              Number(el.purchaseBatchItem?.originalQuantity) >
              Number(el.purchaseBatchItem?.Quantity),
            // ---------------------------------------------------------
            Batch_Name: el.purchaseBatchItem?.Batch_Name,
            parentBatchBarCode: el.purchaseBatchItem?.parentBatchItemName,
            parentBatchItemName: el.purchaseBatchItem?.parentBatchItemName,
            parentBatchName: el.purchaseBatchItem?.parentBatchName,
            DriverName: el.purchaseBatchItem?.DriverName,
            DriverPhoneNumber: el.purchaseBatchItem?.DriverPhoneNumber,
            TruckNumber: el.purchaseBatchItem?.TruckNumber,
            ...(el?.purchaseBatchItem
              ? {
                  DeliveryDate: moment(
                    new Date(el?.purchaseBatchItem?.DeliveryDate)
                  ),
                  ManDate: moment(new Date(el?.purchaseBatchItem?.ManDate)),
                  ExpireDate: moment(
                    new Date(el?.purchaseBatchItem?.ExpireDate)
                  ),
                }
              : {}),
            pendingId: id,
            totalLandingCost: currency(el.UnitPrice).multiply(el.Quantity)
              .value,
          }))
        );
        setShowEditPurchaseHistoryModalWH(true);
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to load Purchase, Try again");
    } finally {
      setIsLoading(false);
    }
  };

  const editPurchase = ({ InvoiceNo, id }) => {
    getPurchase({ InvoiceNo: InvoiceNo, id });
  };

  const editPurchaseWarehouse = ({ InvoiceNo, Warehouse, id }) => {
    getPurchaseWH({ InvoiceNo: InvoiceNo, id, Warehouse });
  };

  const printGRNWH = async (InvoiceNo) => {
    await GRNDialog({
      title: "Transaction posted successfully",
      // description: "...",
      InvoiceNo,
      useAPI: `${backendUrl}/api/warehouse/pdf/grn`,
    });
  };

  const showRFQ = async (formId, childId) => {
    const { form } = await fetchActionsUtil(
      `${backendUrl}/api/forms/single-filled-form?FormID=${formId}&childFormId=${childId}`,
      "GET"
    );
    setSelectedFilledForm(form);
    setOpenViewPO(true);
    // console.log(form);
  };

  const saveForm = async (filledFormData) => {
    const payload = {};
    payload.Template = filledFormData;
    payload.childFormId = childFormId;

    const formData = formDataForFormBuider(payload);

    if (
      await ConfirmDialog({
        title: "Submit",
        description: "Are you sure, you want to submit",
      })
    ) {
      const res = await sendFormData(
        `${backendUrl}/api/forms/update-form`,
        formData
      );

      toast.success(res.message);
    }
  };

  // async function handleViewPO(PONumber) {
  //   console.log("this si the po", PONumber);
  //   const data = await fetchActionsUtil(
  //     `${backendUrl}/api/vendors/get-PO/${PONumber}`,
  //     "GET"
  //   );

  //   console.log(data);
  // }
  return (
    <>
      <main className="inner-page-outlet">
        <PageHeader
          name="Approve Stock Inbound"
          icon={<RecieptIcon />}
          description=""
        />

        <main className="cash-book">
          <div className="content">
            <div className="content-main">
              <div className="content-body">
                <header
                  className="flex-wrap gap-3 position-relative"
                  style={{ zIndex: 10 }}
                >
                  <div className="d-flex flex-grow-1  gap-3">
                    <div className="global-search-area col col-md-6">
                      <MagnifyIcon />
                      <Form.Control
                        id="queryParams-q"
                        className=""
                        name="q"
                        value={filterParams.q}
                        onChange={(e) => handleSearchQueryChange(e)}
                        placeholder="Search... Vendor, Invoice No"
                      />
                    </div>

                    <Form.Group>
                      <Select
                        classNamePrefix="form-select"
                        placeholder="Status"
                        menuPosition="fixed"
                        isSearchable={false}
                        onChange={({ value }) =>
                          setFilterParams({
                            ...filterParams,
                            page: 1,
                            Status: value,
                          })
                        }
                        value={options.find(
                          (el) => el.value === filterParams.Status
                        )}
                        options={options}
                      />
                    </Form.Group>

                    <RsDateRangePicker
                      placement="bottomEnd"
                      defaultValue={
                        queryParams.startDate && queryParams.endDate
                          ? [
                              parse(
                                queryParams.startDate,
                                "yyyy-MM-dd",
                                new Date()
                              ),
                              parse(
                                queryParams.endDate,
                                "yyyy-MM-dd",
                                new Date()
                              ),
                            ]
                          : []
                      }
                      onClean={() => clearDateRange()}
                      onOk={(date) => filterByDateRange(date)}
                    />

                    <button
                      onClick={() => refetch()}
                      className="btn text-primary mx-3"
                    >
                      <CachedIcon />
                    </button>
                  </div>
                  <div className="d-flex gap-3">
                    {/*  
                  <Button
                    disabled={isEmpty(data?.pendingInventoryActions)}
                    onClick={() => disapproveAll()}
                    variant="danger"
                    className="text-white px-3"
                  >
                    Disapprove All
                  </Button>*/}
                  </div>
                </header>

                <div className="px-md-4">
                  <Table
                    borderless
                    responsive
                    hover
                    striped
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        <th />
                        <th>S/N</th>
                        <th>Type</th>
                        <th>Invoice No.</th>
                        <th>PO No.</th>
                        <th>Vendor</th>
                        <th>Total Value</th>
                        <th>Sent By</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th> {"  "}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.pendingInventoryActions?.map((el, index) => (
                        <tr className="p-cursor" key={index}>
                          <td />
                          <td>{index + 1}</td>
                          <td onClick={() => viewDetails(el)}>{el.Type}</td>
                          <td onClick={() => viewDetails(el)}>
                            {el.InvoiceNo || "..."}
                          </td>
                          <td onClick={() => viewDetails(el)}>
                            {el.PONumber || "..."}
                          </td>
                          <td onClick={() => viewDetails(el)}>
                            {el.vendorName}
                          </td>
                          <td onClick={() => viewDetails(el)}>
                            {currency(el.Amount, {
                              symbol: "",
                            }).format()}
                          </td>

                          <td onClick={() => viewDetails(el)}>{el.UserName}</td>
                          <td
                            onClick={() => viewDetails(el)}
                            className="fw-bold"
                          >
                            {el.Status === "Approved" ? (
                              <span className="text-success">{el.Status}</span>
                            ) : (
                              <span>{el.Status}</span>
                            )}
                          </td>
                          <td onClick={() => viewDetails(el)}>
                            {" "}
                            {el.Date_Log
                              ? format(new Date(el.Date_Log), "dd MMM, yyyy")
                              : "..."}{" "}
                          </td>
                          <td className="position-relative">
                            <div className="d-flex gap-3">
                              {el.Type === "EXPENSE" &&
                              el.Status !== "Approved" ? (
                                <>
                                  <Button
                                    className="ga-btn"
                                    onClick={() => approve(el)}
                                  >
                                    Approve
                                  </Button>
                                  <Button
                                    className="ga-btn"
                                    variant="outline-danger"
                                    onClick={() => disapprove(el)}
                                  >
                                    Disapprove
                                  </Button>
                                </>
                              ) : null}

                              <Dropdown style={{ margin: 0 }}>
                                <Dropdown.Toggle
                                  variant=""
                                  className="bg-light-blue text-primary"
                                  bsPrefix="print more"
                                >
                                  <DotsHorizontalIcon />
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                  popperConfig={{
                                    strategy: "fixed",
                                  }}
                                  renderOnMount
                                  className="dropdown-with-icons"
                                >
                                  {el.Status === "Pending" && (
                                    <>
                                      <Dropdown.Item
                                        as="button"
                                        onClick={() => viewDetails(el)}
                                      >
                                        <DropdownEyeIcon /> Open
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        as="button"
                                        onClick={() => disapprove(el)}
                                      >
                                        <DropdownCloseIcon /> Reject
                                      </Dropdown.Item>
                                    </>
                                  )}
                                  <Dropdown.Item
                                    as="a"
                                    // href={`${backendUrl}/api/reports/pdf/grn/${
                                    //   el.InvoiceNo
                                    // }`

                                    // }

                                    href={
                                      el?.Type === "WAREHOUSE STOCK INBOUND"
                                        ? `${backendUrl}/api/warehouse/pdf/grn/${
                                            el.InvoiceNo
                                          }`
                                        : `${backendUrl}/api/reports/pdf/grn/${
                                            el.InvoiceNo
                                          }`
                                    }
                                    target="blank"
                                  >
                                    <RecieptIcon /> View GRN
                                  </Dropdown.Item>
                                  {el?.PendingInventoryAction_PO && (
                                    <>
                                      {el?.PendingInventoryAction_PO?.FormID ===
                                      null ? (
                                        <Dropdown.Item
                                          as="button"
                                          className="text-primary p-cursor fw-bold"
                                          onClick={() => {
                                            setSelectedRFQ(
                                              el?.PendingInventoryAction_PO
                                            );
                                            setShowCreateQuoteModal(true);
                                            setDueDate(
                                              el?.PendingInventoryAction_PO
                                                ?.dueDate
                                            );
                                          }}
                                        >
                                          <DropdownEyeIcon /> View PO
                                        </Dropdown.Item>
                                      ) : el?.PendingInventoryAction_PO
                                          ?.docName === null ? null : (
                                        <Dropdown.Item as="button">
                                          <span
                                            onClick={() => {
                                              setImageUrl(
                                                `${backendUrl}/api/vendors/get-po-doc/${
                                                  el?.PendingInventoryAction_PO
                                                    ?.docName
                                                }`
                                              );
                                              setImageName(
                                                el?.PendingInventoryAction_PO
                                                  ?.POName
                                              );
                                              setShowImage(true);
                                            }}
                                            className="text-primary p-cursor fw-bold"
                                          >
                                            <DropdownEyeIcon /> View PO{" "}
                                          </span>
                                        </Dropdown.Item>
                                      )}
                                      {el?.PendingInventoryAction_PO?.FormID &&
                                      el?.PendingInventoryAction_PO
                                        ?.childFormId ? (
                                        <Dropdown.Item
                                          as="button"
                                          onClick={() => {
                                            setImageName(
                                              el?.PendingInventoryAction_PO
                                                ?.POName
                                            );
                                            setChildFormId(
                                              el.PendingInventoryAction_PO
                                                ?.childFormId
                                            );
                                            showRFQ(
                                              el?.PendingInventoryAction_PO
                                                ?.FormID,
                                              el?.PendingInventoryAction_PO
                                                ?.childFormId
                                            );
                                          }}
                                          className="text-success p-cursor mx-3 fw-bold"
                                        >
                                          View Form
                                        </Dropdown.Item>
                                      ) : null}
                                    </>
                                  )}
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => deletePermit(el)}
                                  >
                                    <DeleteIcon /> Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  {!isFetching &&
                  isSuccess &&
                  isEmpty(data?.pendingInventoryActions) ? (
                    <NoTableItem queryParams={queryParams} />
                  ) : null}
                </div>

                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data.count / queryParams.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      document.body.scrollTop = document.documentElement.scrollTop = 0;
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>

                <div className={`summaryNew `}>
                  <div className="gridCont">
                    <div className="gridChild gridChildBorderLeftBlue">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(data?.totalAmount, { symbol: "" }).format()}
                        </p>
                        <p className="gridChld2">Total Amount</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconUp />
                        <p>5%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Outlet />

        {/*  {showUpdateInventoryModal && (
        <UpdateItemModal
          showUpdateInventoryModal={showUpdateInventoryModal}
          setShowUpdateInventoryModal={setShowUpdateInventoryModal}
          refetch={refetch}
          printGRN={printGRN}
          requiresApproval={false}
          defaultTableData={showUpdateInventoryModal.DataToPost.stockItems}
          pendingId={showUpdateInventoryModal.id}
        />
      )} */}

        {showEditPurchaseHistoryModal && purchaseHistoryToEdit ? (
          <EditPurchaseHistoryModal
            showEditPurchaseHistoryModal={showEditPurchaseHistoryModal}
            setShowEditPurchaseHistoryModal={(show) => {
              setShowEditPurchaseHistoryModal(show);
              if (!show) setPurchaseHistoryToEdit();
            }}
            refetch={refetch}
            purchaseHistoryToEdit={purchaseHistoryToEdit}
            printGRN={printGRN}
            pendingId={purchaseHistoryToEdit[0].pendingId}
            requiresPONumber={generalSettings.restockRequiresPONumber}
          />
        ) : null}

        {showEditPurchaseHistoryModalWH && purchaseHistoryToEdit ? (
          <EditWarehousePurchaseHistoryModal
            showEditPurchaseHistoryModal={showEditPurchaseHistoryModalWH}
            setShowEditPurchaseHistoryModal={(show) => {
              setShowEditPurchaseHistoryModalWH(show);
              if (!show) setPurchaseHistoryToEdit();
            }}
            refetch={refetch}
            purchaseHistoryToEdit={purchaseHistoryToEdit}
            printGRN={printGRNWH}
            pendingId={purchaseHistoryToEdit[0].pendingId}
            requiresPONumber={generalSettings.restockRequiresPONumber}

          />
        ) : null}

        <ModalLoader
          show={
            deletePermitMutation.isLoading ||
            updatePermitsMutation.isLoading ||
            isLoading
          }
        />
      </main>

      {showCreateQuoteModal && (
        <SingleRFQTableModal
          show={showCreateQuoteModal}
          onHide={setShowCreateQuoteModal}
          selectedRFQ={selectedRFQ}
          disableInput={true}
          fetchPO={true}
          addToInvemtry={
            selectedRFQ?.Status === "Approve" ||
            selectedRFQ?.Status === "Approved and Partly Received"
          }
          refetch={refetch}
          headerNo={`Purchase Order No : ${selectedRFQ?.POId}`}
          headerName={`Purchase Order Name : ${selectedRFQ?.POName}`}
          showSupplyDate
          dueDate={dueDate}
          usage={""}
        />
      )}

      <ViewImageModal
        showImage={showImage}
        setShowImage={setShowImage}
        url={imageUrl}
        title={imageName}
      />

      {openViewPO && (
        <ViewPurchaseOrderModal
          form={selectedFilledForm}
          showImage={openViewPO}
          setShowImage={setOpenViewPO}
          title={imageName}
          canFill={true}
          showTableCheckbox={true}
          showSendButton={true}
          saveForm={saveForm}
          printForm
        />
      )}
    </>
  );
}
