import { Modal, Form, Button } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "./../assets/scss/new-entity-modal.scss";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import DatePickerCustomInput from "./utils/DatePickerCustomInput";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import { LockIcon } from "./Icons";
import { useFormik, getIn } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation, useQuery } from "react-query";
import { services } from "../config";
import { toast } from "react-toastify";
import LoginDialog from "./LoginDialog";
import { useState, useMemo } from "react";
import { nanoid } from "../utils/helpers";
import moment from "moment";
import { useAuth } from "../hooks/useAuth";
import CurrencyInput from "react-currency-input-field";
import photoUrl from "../assets/images/profile.png";
import UploadImage from "./utils/UploadImage";
import { isEmpty } from "lodash";
import { uniqBy } from "lodash";

import {
  sendFormData,
  fetchActionsUtil,
  VendorCat as vendorCat,
  VendorPayment,
} from "../utils/helpers";
import ConfirmDialog from "./ConfirmDialogue";
import { encryptData } from "../utils/helpers";
import { AdvanceVendor } from "../config";
import UploadPDF from "./utils/UploadPDF";
import CitySelector from "./CitySelector";

export default function NewVendorModal({
  showCreateNewVendorModal,
  setShowCreateNewVendorModal,
  setSelectedVendor,
  refetch,
}) {
  const { backendUrl, token } = useAuth();
  const [, setInputValue] = useState("");
  const [jobsValue, setJobsValue] = useState([]);

  const formik = useFormik({
    initialValues: {
      Vendor_ID: String(Date.now()),
      CompanyName: "",
      ContactName: "",
      ContactTitle: "",
      Address: "",
      Phone: "",
      Email: "",
      // City: "",
      state: "",
      LGA: "",
      AccountNumber: "",
      AccountName: "",
      Bank: "",
      DateCreated: moment(),
      Vendor_Cat: "",
      Certifications: [
        {
          name: "",
          DateObtained: moment(),
          expityDate: moment(),
          certFile: "",
          certName: String(Date.now()),
        },
      ],
      fromRange: 0,
      toRange: 0,
      jobs: [],
      paymentType: "",
      paymentPercent: "",
      paymentDueDays: "",
    },
    validationSchema: yup.object().shape({
      Vendor_ID: yup.string().required("required"),
      CompanyName: yup.string().required("required"),
      Vendor_Cat: yup.string().required("required"),
      Email: yup.string().required("required"),
    }),
    onSubmit: (values) => {
      const {
        DateCreated,
        paymentPercent,
        paymentType,
        paymentDueDays,
      } = values;
      if (typeof DateCreated === "string") {
        return formik.setFieldError("DateCreated", "Invalid date");
      }

      if (paymentType === "Part_Payment") {
        if (
          parseFloat(paymentPercent) > 100 ||
          parseFloat(paymentPercent) < 0
        ) {
          return formik.setFieldError("paymentPercent", "Enter btw 1 - 100");
        }
      }

      if (paymentType === "Post_Payment") {
        if (paymentDueDays === "") {
          return formik.setFieldError("paymentDueDays", "Can not be empty");
        }
      }

      values.jobs = jobsValue.map((d) => d.label);
      // values.company = encryptData(backendUrl);
      values.company = backendUrl;

      submit({
        ...values,
        DateCreated: DateCreated.format(),
      });
    },
  });

  const sendvendor = async (url, formData) => {
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      body: formData,
      headers: {
        // Accept: "Application/json",
        // "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      console.log(response);
      response = await response.json();
      throw new Error(response.message);
    }

    const data = await response.json();
    return data;
  };

  const createVendorMutation = useMutation(
    (payload) => sendvendor(`${backendUrl}/api/vendors/create`, payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success("Vendor Created Successfully");

        if (setSelectedVendor) setSelectedVendor(data.vendor);
        formik.resetForm();
        setShowCreateNewVendorModal(false);
        if (refetch) refetch();
      },
      onError: ({ err, message }) => {
        console.log(err);
        toast.error(message);
      },
    }
  );

  const submit = async (payload) => {
    const { Certifications, jobs, ...rest } = payload;
    const formData = new FormData();
    for (const [key, value] of Object.entries(rest)) {
      formData.append(key, value);
    }

    if (jobs.length > 0) {
      formData.append("jobs", JSON.stringify(jobs));
    }

    const certDatas = [];
    let error = false;
    if (Certifications.length > 0) {
      Certifications.forEach((element) => {
        if (element.name !== "" && element.certFile === "") {
          error = true;
        }
        if (element.certFile !== "") {
          formData.append(
            element.certName,
            element.certFile,
            `${element.certName}_certFile`
          );
        }
        if (element.name !== "") {
          const certData = {
            name: element.name,
            DateObtained: element.DateObtained,
            expityDate: element.expityDate,
            certName: element.certName,
          };
          certDatas.push(certData);
        }
      });
    }

    if (error) {
      return toast.error("Upload a file or delete the certification");
    }

    certDatas.length > 0 &&
      formData.append("certData", JSON.stringify(certDatas));

    if (
      await ConfirmDialog({
        title: "Create Vendor",
        description: `Are you sure you want to Create this Account`,
      })
    ) {
      // console.log(payload);
      createVendorMutation.mutate(formData);
    }
  };

  const onChangeCertDate = (dates, ind, name) => {
    const newDate = formik.values.Certifications.map((cert, i) => {
      if (ind !== i) return cert;
      const value = dates ? dates : moment();
      return { ...cert, [name]: value };
    });
    formik.setFieldValue("Certifications", newDate);
  };

  const OnChangeInput = (e, ind) => {
    const { name, value, files } = e.target;
    const newData = formik.values.Certifications.map((cert, i) => {
      if (ind !== i) return cert;
      if (name === "certFile") return { ...cert, [name]: files[0] };
      return { ...cert, [name]: value };
    });
    formik.setFieldValue("Certifications", newData);
  };

  const addMoreCert = () => {
    formik.setFieldValue(
      "Certifications",
      formik.values.Certifications.concat({
        name: "",
        DateObtained: moment(),
        expityDate: moment(),
        certFile: "",
        certName: String(Date.now()),
      })
    );
  };

  const removeCert = (idx) => {
    if (formik.values.Certifications.length === 1) return;
    const removecert = formik.values.Certifications.filter((r, i) => i !== idx);
    formik.setFieldValue("Certifications", removecert);
  };

  const { data } = useQuery(
    ["VENDORS-JOBS"],
    () => fetchActionsUtil(`${backendUrl}/api/vendors/vendor-jobs`, "GET"),
    {
      keepPreviousData: true,
    }
  );
  const vendorCategory = useQuery(
    ["GET ALL VENDOR CATEGORY"],
    () =>
      fetchActionsUtil(`${backendUrl}/api/vendors/get-vendor-category`, "GET"),
    {
      keepPreviousData: true,
    }
  );

  const vendorJobs = useMemo(() => {
    return [...new Set(data?.vendorJobs?.map((d) => d.name))].map((el) => ({
      label: el,
      value: el,
    }));
  }, [data]);

  const VendorCat = useMemo(() => {
    if (vendorCategory?.data && vendorCategory?.data.data) {
      let cat = vendorCategory?.data.data;

      cat = cat?.map((arr) => ({ label: arr.Category, value: arr.Category }));
      cat = vendorCat.concat(cat);
      cat = uniqBy(cat, "label");
      return cat;
    }
  }, [vendorCategory?.data]);

  return (
    <Modal
      show={showCreateNewVendorModal}
      onHide={() => setShowCreateNewVendorModal(false)}
      dialogClassName="item-select-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      className="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>Vendor Information</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          onSubmit={formik.handleSubmit}
          className=" pb-2"
          autoComplete="off"
        >
          <div className="">
            <div className="row">
              <div className="col-6">
                <Form.Group className="mb-3 pb-2">
                  <Form.Label className="mb-2">Vendor ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="xxxxxx"
                    name="Vendor_ID"
                    value={formik.values.Vendor_ID}
                    style={{ pointerEvents: "none" }}
                  />
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3 pb-2">
                  <Form.Label className="mb-1">Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Company Name"
                    name="CompanyName"
                    value={formik.values.CompanyName}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.CompanyName && !!formik.errors.CompanyName
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.CompanyName}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <Form.Group className="mb-3 pb-2">
                  <Form.Label className="mb-1">Contact Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Contact Name"
                    name="ContactName"
                    value={formik.values.ContactName}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.ContactTitle &&
                      !!formik.errors.ContactTitle
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.ContactTitle}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3 pb-2">
                  <Form.Label className="mb-1">Contact Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Contact Title"
                    name="ContactTitle"
                    value={formik.values.ContactTitle}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.ContactTitle &&
                      !!formik.errors.ContactTitle
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.ContactTitle}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Email Address</Form.Label>
                <Form.Control
                  className=""
                  type="email"
                  placeholder="Enter email"
                  name="Email"
                  value={formik.values.Email}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.Email && !!formik.errors.Email}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Email}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Phone Number</Form.Label>
                <Form.Control
                  className=""
                  type="tel"
                  placeholder="(555) xxxx xxxx"
                  name="Phone"
                  value={formik.values.Phone}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.Phone && !!formik.errors.Phone}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Phone}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className="row">
              <Form.Group className="col-3 mb-3">
                <Form.Label>Vendor Category</Form.Label>
                <Select
                  classNamePrefix="form-select"
                  menuPosition="fixed"
                  menuPlacement="auto"
                  placeholder="Category"
                  isSearchable={false}
                  value={VendorCat?.find(
                    (d) => d.value === formik.values.Vendor_Cat
                  )}
                  options={VendorCat}
                  onChange={(selected) => {
                    formik.setFieldValue("Vendor_Cat", selected.value);
                  }}
                />
                {formik.touched.Vendor_Cat && !!formik.errors.Vendor_Cat ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.Vendor_Cat}
                  </span>
                ) : null}
              </Form.Group>
              <Form.Group className="col-3 mb-3">
                <Form.Label>Payment Type</Form.Label>
                <Select
                  classNamePrefix="form-select"
                  menuPosition="fixed"
                  menuPlacement="auto"
                  placeholder="Payment Type"
                  isSearchable={false}
                  value={VendorPayment.find(
                    (d) => d.value === formik.values.paymentType
                  )}
                  options={VendorPayment}
                  onChange={(selected) => {
                    formik.setFieldValue("paymentType", selected.value);
                  }}
                />
                {formik.touched.paymentType && !!formik.errors.paymentType ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.paymentType}
                  </span>
                ) : null}
              </Form.Group>

              {formik.values.paymentType === "Part_Payment" && (
                <Form.Group className="col-3 mb-3">
                  <Form.Label>Part Payment Percentage</Form.Label>
                  <Form.Control
                    type="number"
                    className="form-control"
                    value={formik.values.paymentPercent}
                    placeholder="Part Payment Percent"
                    allowNegativeValue={false}
                    allowDecimals={false}
                    name="paymentPercent"
                    onChange={formik.handleChange}
                    max={100}
                    min={1}
                  />
                  {formik.touched.paymentPercent &&
                  !!formik.errors.paymentPercent ? (
                    <span className="custom-invalid-feedback">
                      {formik.errors.paymentPercent}
                    </span>
                  ) : null}
                </Form.Group>
              )}
              {formik.values.paymentType === "Post_Payment" && (
                <Form.Group className="col-3 mb-3">
                  <Form.Label>Payment Due Days</Form.Label>
                  <Form.Control
                    type="number"
                    className="form-control"
                    value={formik.values.paymentDueDays}
                    placeholder="Payment Due Days"
                    allowNegativeValue={false}
                    allowDecimals={false}
                    name="paymentDueDays"
                    onChange={formik.handleChange}
                    max={100}
                    min={1}
                  />
                  {formik.touched.paymentDueDays &&
                  !!formik.errors.paymentDueDays ? (
                    <span className="custom-invalid-feedback">
                      {formik.errors.paymentDueDays}
                    </span>
                  ) : null}
                </Form.Group>
              )}

              <Form.Group className="col-3 mb-3">
                <Form.Label htmlFor="DateCreated">Date</Form.Label>
                <Datetime
                  dateFormat="MMM DD, YYYY"
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside={true}
                  name="DateCreated"
                  inputProps={{
                    className: `date-input form-control ${
                      formik.touched.DateCreated && !!formik.errors.DateCreated
                        ? "is-invalid"
                        : ""
                    }`,
                    placeholder: "Select date",
                    readOnly: true,
                  }}
                  value={formik.values.DateCreated}
                  onChange={(date) => {
                    formik.setFieldValue("DateCreated", date, true);
                  }}
                  onBlur={() => formik.setFieldTouched("BOB", true)}
                />
                {formik.touched.DateCreated && !!formik.errors.DateCreated ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.DateCreated}
                  </span>
                ) : null}
              </Form.Group>
            </div>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Vendor Address</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter Vendor address"
                name="Address"
                rows={5}
                value={formik.values.Address}
                onChange={formik.handleChange}
                isInvalid={formik.touched.Address && !!formik.errors.Address}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.Address}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="row">
              <div className="col-md-6">
                {/* <Form.Group className="mb-3 pb-2">
                  <Form.Label className="mb-1">City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter City"
                    name="City"
                    value={formik.values.City}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.City && !!formik.errors.City}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.City}
                  </Form.Control.Feedback>
                </Form.Group> */}
                <CitySelector formik={formik} />
              </div>

              {AdvanceVendor && (
                <div className="col-6">
                  <Form.Group className="mb-3 pb-2">
                    <div className="d-flex justify-content-between">
                      <Form.Label>Min Price</Form.Label>
                      <Form.Label>Max Price</Form.Label>
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <CurrencyInput
                          className="form-control"
                          value={formik.values.fromRange}
                          placeholder="Enter Start Range"
                          allowNegativeValue={false}
                          allowDecimals={false}
                          name="fromRange"
                          onValueChange={(value, name) => {
                            formik.setFieldValue(name, value);
                          }}
                        />
                      </div>

                      <div className="col-6">
                        <CurrencyInput
                          className="form-control"
                          value={formik.values.toRange}
                          placeholder="Enter End Range"
                          allowNegativeValue={false}
                          allowDecimals={false}
                          name="toRange"
                          onValueChange={(value, name) => {
                            formik.setFieldValue(name, value);
                          }}
                        />
                      </div>
                    </div>
                  </Form.Group>
                </div>
              )}
            </div>
            {/*  */}
            <div className="row">
              <div className="col-6">
                <Form.Group className="mb-3 pb-2">
                  <Form.Label className="mb-1">Account Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Account Name"
                    name="AccountName"
                    value={formik.values.AccountName}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.AccountName && !!formik.errors.AccountName
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.AccountName}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-6">
                <Form.Group className="mb-3 pb-2">
                  <div className="d-flex justify-content-between">
                    <Form.Label>Bank</Form.Label>
                    <Form.Label>Account Number</Form.Label>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <Form.Control
                        type="text"
                        placeholder="Enter Bank"
                        name="Bank"
                        value={formik.values.Bank}
                        onChange={formik.handleChange}
                        isInvalid={formik.touched.Bank && !!formik.errors.Bank}
                      />
                    </div>

                    <div className="col-6">
                      <Form.Control
                        type="text"
                        placeholder="Enter Account Number"
                        name="AccountNumber"
                        value={formik.values.AccountNumber}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.AccountNumber &&
                          !!formik.errors.AccountNumber
                        }
                      />
                    </div>
                  </div>
                </Form.Group>
              </div>
            </div>
            {/*  */}

            {AdvanceVendor && (
              <>
                <div className="my-3">
                  <Form.Label className="mb-1">Products/Services</Form.Label>
                  <CreatableSelect
                    isMulti
                    isClearable
                    onChange={(newValue) => setJobsValue(newValue)}
                    onInputChange={(newValue) => setInputValue(newValue)}
                    options={vendorJobs}
                    value={jobsValue}
                  />
                </div>

                <div className="text-center">
                  <Form.Label className="mt-2">Certifications</Form.Label>
                </div>

                {formik.values.Certifications.length > 0 &&
                  formik.values.Certifications.map((cert, inxd) => {
                    return (
                      <div className="mb-3 border rounded-1 p-4 position-relative">
                        <span
                          className="position-absolute top-0 start-100 translate-middle badge rounded bg-danger p-cursor"
                          onClick={() => removeCert(inxd)}
                        >
                          X
                        </span>
                        <div>
                          <Form.Label className="mb-1">
                            Certificate Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Certificate Name"
                            name="name"
                            value={cert.name}
                            onChange={(e) => OnChangeInput(e, inxd)}
                          />
                        </div>
                        <div className="row">
                          <div className="col-6 my-3">
                            <Form.Label className="mb-1">
                              Date Obtained
                            </Form.Label>
                            <Datetime
                              dateFormat="MMM DD, YYYY"
                              timeFormat={false}
                              closeOnSelect={true}
                              closeOnClickOutside={true}
                              name="DateObtained"
                              inputProps={{
                                className: `date-input form-control`,
                                placeholder: "Select date",
                                readOnly: true,
                              }}
                              value={cert.DateObtained}
                              onChange={(date) => {
                                onChangeCertDate(date, inxd, "DateObtained");
                              }}
                            />
                          </div>
                          <div className="col-6 my-3">
                            <Form.Label className="mb-1">
                              Expiry Date
                            </Form.Label>
                            <Datetime
                              dateFormat="MMM DD, YYYY"
                              timeFormat={false}
                              closeOnSelect={true}
                              closeOnClickOutside={true}
                              name="expityDate"
                              inputProps={{
                                className: `date-input form-control`,
                                placeholder: "Select date",
                                readOnly: true,
                              }}
                              value={cert.expityDate}
                              onChange={(date) => {
                                onChangeCertDate(date, inxd, "expityDate");
                              }}
                            />
                          </div>
                          <Form.Label className="mb-1">
                            Upload Certificate
                          </Form.Label>
                          {/* <UploadImage
                            onFileUpload={(e) => OnChangeInput(e, inxd)}
                            name="certFile"
                            image={cert.certFile}
                            size="w-50 h-50"
                          /> */}
                          <UploadPDF
                            onFileUpload={(e) => OnChangeInput(e, inxd)}
                            name="certFile"
                            image={cert.certFile}
                            // clearImage={clearImage}
                            size="w-30 h-30"
                          />
                        </div>
                      </div>
                    );
                  })}

                <div className="my-3 text-primary fw-bold fs-5">
                  <button
                    type="button"
                    className="badge bg-primary"
                    onClick={addMoreCert}
                  >
                    +Add More
                  </button>
                </div>
              </>
            )}
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          type="submit"
          className="submit-btn mt-3 py-2"
          disabled={createVendorMutation.isLoading}
          onClick={() => formik.submitForm()}
        >
          {createVendorMutation.isLoading ? "Please wait..." : "Create Vendor"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
