import { Button, Form, Table } from "react-bootstrap";
import {
  CheckCircleIcon,
  UserIconTwo,
  MoneyDollarIcon,
  PageHeaderListIcon,
  FilterTwoIcon,
  FilterCollapseIcon,
} from "../../../components/Icons";
import "./../../../assets/scss/reports/cashbook.scss";
import { useRef } from "react";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useQuery } from "react-query";
import currency from "currency.js";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
} from "../../../utils/hooks";
import Select from "react-select";
import { useState } from "react";
import RsDateRangePicker from "../../utils/RsDateRangePicker";
import { format, parse } from "date-fns";
import { employeeFullName, reactSelectTheme } from "../../../utils/helpers";
import { useEffect } from "react";
import { appSettings } from "../../../config";
import { IsPrivileged } from "../../DisplayChildElement";

export default function SalesRepReports({ Nav }) {
  const reportTypeOptions = [
    {
      value: "Detailed",
      label: "Detailed",
    },
    {
      value: "Summary",
      label: "Summary",
    },
    {
      value: "Full",
      label: "Full",
    },
  ];
  const initialFilterParams = {
    reportType: "Detailed",
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    isBatchStandard: appSettings.isBatchStandard,
    ProductName: "",
    page: 1,
    limit: 40,
  };
  const { backendUrl } = useAuth();
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [profit, setProfit] = useLocalStorage("profitRepSales", 0);

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const getStats = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/sales/stats`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      //  credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    let { data } = await response.json();

    data.branch = [
      {
        label: "All",
        value: "",
      },
      ...data.branch
        .map((el) => ({
          label: el.Branch,
          value: el.Branch,
        }))
        .filter((el) => Boolean(el.value)),
    ];

    data.salesRep = [
      {
        label: "All",
        value: "",
      },
      ...data.salesRep
        .map((el) => ({
          label: employeeFullName(el),
          value: el.EmployeeID,
        }))
        .filter((el) => el.label),
    ];

    data.productName = [
      {
        label: "All",
        value: "",
      },
      ...data.productName
        .map((el) => ({
          label: el.ProductName,
          value: el.ProductName,
        }))
        .filter((el) => el.value),
    ];

    return data;
  };

  const {
    error,
    data = {
      salesRep: [],
      branch: 0,
      productName: [],
    },
    isSuccess,
    refetch,
    isFetching,
  } = useQuery(["SALES_REP_REPORTS_STAT"], () => getStats(), {});

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  // isprivileged
  return (
    <IsPrivileged roleName="Sales Rep Reports">
      <main className="cash-book">
        <div className="content">
          <section className="salesRepStats pb-4">
            <div className="stat">
              <div className="icon">
                <UserIconTwo />
              </div>
              <div className="details">
                <p>
                  {currency(data?.totalCustomers, {
                    symbol: "",
                    precision: 0,
                  }).format()}
                </p>
                <span>Customers</span>
              </div>
            </div>

            <div className="stat">
              <div className="icon icon-1">
                <PageHeaderListIcon />
              </div>
              <div className="details">
                <p>
                  {currency(data?.totalSales, {
                    symbol: "",
                    precision: 2,
                  }).format()}
                </p>
                <span>Sales</span>
              </div>
            </div>

            <div className="stat">
              <div className="icon icon-2">
                <MoneyDollarIcon />
              </div>
              <div className="details">
                <p>{profit}</p>
                <span>Profit</span>
              </div>
            </div>

            {/* <div className="stat">
            <div className="icon icon-3">
              <CheckCircleIcon />
            </div>
            <div className="details">
              <p>0.00</p>
              <span>Receivable</span>
            </div>
          </div> */}
          </section>

          <div className="position-relative">
            <div className="title-actions-holder">{Nav}</div>

            <div className="cashbook-wrapped">
              <main className="cash-book">
                <div className="content">
                  <div className="content-main">
                    <div
                      className={`filter-area ${showFilter ? "open" : "close"}`}
                    >
                      <div className="filter-header">
                        <h2>
                          <FilterTwoIcon /> Filters
                        </h2>

                        <Button
                          variant="white"
                          onClick={() => setShowFilter(!showFilter)}
                        >
                          <FilterCollapseIcon />
                        </Button>
                      </div>
                      <div className="filter-body">
                        <Form>
                          <div className="body">
                            <Form.Group className="mb-2-5">
                              <Form.Label>Sales Rep</Form.Label>
                              <Select
                                classNamePrefix="form-select"
                                menuPosition="fixed"
                                menuPlacement="auto"
                                name="salesRep"
                                placeholder="All"
                                theme={reactSelectTheme}
                                isSearchable={true}
                                key={data?.salesRep}
                                onChange={(selected) => {
                                  setFilterParams({
                                    ...filterParams,
                                    salesRep: selected.value,
                                  });
                                }}
                                value={
                                  filterParams?.salesRep && data?.salesRep
                                    ? data?.salesRep.find(
                                        (el) =>
                                          el.value === filterParams?.salesRep
                                      )
                                    : {
                                        value: "",
                                        label: "All",
                                      }
                                }
                                options={data?.salesRep}
                              />
                            </Form.Group>

                            <Form.Group className="mb-2-5">
                              <Form.Label>Product / Manufacturer</Form.Label>
                              <Select
                                name="ProductName"
                                classNamePrefix="form-select"
                                menuPosition="fixed"
                                menuPlacement="auto"
                                placeholder="All"
                                isSearchable={true}
                                key={data?.ProductName}
                                onChange={(selected) => {
                                  setFilterParams({
                                    ...filterParams,
                                    ProductName: selected.value,
                                  });
                                }}
                                value={
                                  data?.productName.find(
                                    (el) =>
                                      el.value === queryParams?.ProductName
                                  ) || ""
                                }
                                options={data?.productName || []}
                              />
                            </Form.Group>

                            <Form.Group className="mb-2-5">
                              <Form.Label>Report Type</Form.Label>
                              <Select
                                classNamePrefix="form-select"
                                menuPosition="fixed"
                                menuPlacement="auto"
                                placeholder="All"
                                theme={reactSelectTheme}
                                isSearchable={true}
                                onChange={(selected) => {
                                  setFilterParams({
                                    ...filterParams,
                                    reportType: selected.value,
                                  });
                                }}
                                value={
                                  filterParams?.reportType
                                    ? reportTypeOptions.find(
                                        (el) =>
                                          el.value === filterParams?.reportType
                                      )
                                    : reportTypeOptions[0]
                                }
                                options={reportTypeOptions}
                              />
                            </Form.Group>

                            <Form.Group className="mb-2-5">
                              <Form.Label>Branch</Form.Label>
                              <Select
                                classNamePrefix="form-select"
                                menuPosition="fixed"
                                menuPlacement="auto"
                                name="branch"
                                placeholder="All"
                                theme={reactSelectTheme}
                                isSearchable={true}
                                key={data?.branch}
                                onChange={(selected) => {
                                  setFilterParams({
                                    ...filterParams,
                                    branch: selected.value,
                                  });
                                }}
                                value={
                                  filterParams?.branch && data?.branch
                                    ? data?.branch.find(
                                        (el) =>
                                          el.value === filterParams?.branch
                                      )
                                    : {
                                        value: "",
                                        label: "All",
                                      }
                                }
                                options={data?.branch}
                              />
                            </Form.Group>

                            <hr className="mt-3 mb-4" />

                            <Form.Group className="">
                              <Form.Label>Date Range</Form.Label>
                              <div className="position-relative">
                                <RsDateRangePicker
                                  placement="auto"
                                  value={
                                    filterParams.startDate &&
                                    filterParams.endDate
                                      ? [
                                          parse(
                                            filterParams.startDate,
                                            "yyyy-MM-dd",
                                            new Date()
                                          ),
                                          parse(
                                            filterParams.endDate,
                                            "yyyy-MM-dd",
                                            new Date()
                                          ),
                                        ]
                                      : []
                                  }
                                  onClean={() => clearDateRange()}
                                  onOk={(date) => filterByDateRange(date)}
                                />
                              </div>
                            </Form.Group>
                          </div>

                          <div className="buttons rounded">
                            <Button onClick={() => reset()} variant="white">
                              Reset
                            </Button>
                            <Button onClick={() => search()} variant="primary">
                              Search
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>

                    <div className="content-body">
                      {" "}
                      <Outlet
                        context={{
                          queryParams,
                          setQueryParams,
                          showFilter,
                          setShowFilter,
                          setProfit,
                        }}
                      />{" "}
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </main>
    </IsPrivileged>
  );
}
