import { Pagination, Form } from "react-bootstrap";
import CachedIcon from "mdi-react/CachedIcon";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import queryString from "query-string";
import { CSVLink } from "react-csv";
import Select from "react-select";
import { ExcelIcon, PDFIcon } from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import useDebounce, {
  useCurrencies,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { useAuth } from "../../hooks/useAuth";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { calculateBalanceSheet } from "../../utils/calculateBalanceSheet";
import { IsPrivileged } from "../DisplayChildElement";

function BalanceSheet() {
  useScrollTop();
  const initialFilterParams = {
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
  };

  const { backendUrl } = useAuth();
  const [excelData, setExcelData] = useState([]);
  const {
    deploymentCurrencies: currenciesOptions,
    prevailingCurrency,
    getCurrencySymbol,
  } = useCurrencies();
  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
    currency: "",
    convertCurrency: prevailingCurrency,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const fetchCustomerLedgers = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/reports/general-ledger?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.main = calculateBalanceSheet(data);
    // console.log(data);
    return data;
  };

  const {
    data = {
      count: 0,
      main: {},
    },
    refetch,
    isFetching,
    // isSuccess,
  } = useQuery(
    ["TrailBalance", queryParams],
    () => fetchCustomerLedgers(queryParams),
    {
      keepPreviousData: true,
    }
  );

  // download excel function
  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/reports/general-ledger?${queryString.stringify(rest)}`,
      "GET"
    );

    const { company } = exData.data;
    const main = calculateBalanceSheet(exData.data);

    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      [company[0]?.CompName],
      ["Balance Sheet Report"],
      [date],
      [""],
      ["ASSETS"],
      ["Current Assets"],
      ["Current Assets", " ", main?.CURRENTASSET],
      ["Cash & Cash Equivalent", " ", main?.CASHANDCASHEQUIVALENT],
      ["Account Receivables", " ", main?.ACCOUNTSRECEIVABLE],
      [" ", "Total Current Assets", main?.TotalCurrentAssets],
      [""],
      ["Fixed Assets"],
      ["Fixed Assets", " ", main?.FIXEDASSET],
      ["Non Current Assets", " ", main?.NONCURRENTASSETS],
      [" ", "Total Fixed Assets", main?.TotalFixedAssets],
      [""],
      ["Other Assets"],
      ["Accumulated Depreciation", " ", main?.ACCUMULATEDDEPRECIATION],
      ["", "Total Other Assets", main?.ACCUMULATEDDEPRECIATION],
      [""],
      ["", "Total Assets", main?.TotalAssets],
      [""],
      [""],
      ["Liabilities and Owner's Equity"],
      ["Current Liabilities"],
      ["Current Liabilities", "", main?.CURRENTLIABILITIES],
      ["Account Payable", " ", main?.ACCOUNTSPAYABLE],
      ["Non Current Liabilities", "", main?.NONCURRENTLIABILITY],
      ["Long Term Liabilities", " ", main?.LONGTERMLIABILITIES],
      ["", "Total Current Liabilities", main?.TotalCurrentLiabilities],
      [""],
      ["Owner's Equity"],
      ["Owner's Funds", " ", main?.OWNEREQUITY],
      [" ", "Total Owner's Equity", main?.OWNEREQUITY],
      [""],
      [
        "",
        "Total Liabilities and Owner's Equity",
        main?.TotalLiabilitiesandOwnerEquity,
      ],
    ];
    console.log(exData);
    setExcelData(exData);
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };
  // isprivileged

  const currencySymbol = useMemo(() => {
    return getCurrencySymbol(
      filterParams.convertCurrency
        ? filterParams.convertCurrency
        : prevailingCurrency
    );
  }, [filterParams.convertCurrency, prevailingCurrency]);

  return (
    <IsPrivileged roleName="Financials">
      <main className="cash-book">
        <div className="content">
          <header className="container pt-5">
            <h1>
              Balance Sheet
              <button
                title="Refresh"
                onClick={() => refetch()}
                className="btn text-primary"
              >
                <CachedIcon />
              </button>
            </h1>
            <div className="actions gap-3">
              <CSVLink
                className="btn print d-none"
                filename={`Balance Sheet(${format(
                  new Date(),
                  "dd-MMM-yyyy hh:mm:ss a"
                )}).csv`}
                data={excelData}
                ref={CSVLinkRef}
              />

              <Form.Group className="position-relative">
                <Form.Label className="fw-5 position-absolute bottom-100 start-0 m-1">
                  Filter By
                </Form.Label>
                <Select
                  classNamePrefix="form-select"
                  placeholder="All Currencies"
                  isSearchable={true}
                  options={currenciesOptions}
                  value={currenciesOptions.find(
                    (el) => el.value == filterParams.currency
                  )}
                  onChange={(selected) =>
                    setFilterParams({
                      ...filterParams,
                      page: 1,
                      currency: selected?.value,
                    })
                  }
                  isClearable={true}
                />
              </Form.Group>

              <Form.Group className="position-relative">
                <Form.Label className="fw-5 position-absolute bottom-100 start-0 m-1">
                  Report In{" "}
                </Form.Label>
                <Select
                  classNamePrefix="form-select"
                  placeholder="Select Currency"
                  isSearchable={true}
                  options={currenciesOptions}
                  value={currenciesOptions.find(
                    (el) => el.value === filterParams.convertCurrency
                  )}
                  onChange={({ value }) =>
                    setFilterParams({
                      ...filterParams,
                      page: 1,
                      convertCurrency: value,
                    })
                  }
                />
              </Form.Group>

              <div className="position-relative">
                <RsDateRangePicker
                  placement="topStart"
                  value={
                    filterParams.startDate && filterParams.endDate
                      ? [
                          parse(
                            filterParams.startDate,
                            "yyyy-MM-dd",
                            new Date()
                          ),
                          parse(filterParams.endDate, "yyyy-MM-dd", new Date()),
                        ]
                      : []
                  }
                  onClean={() => clearDateRange()}
                  onOk={(date) => filterByDateRange(date)}
                />
              </div>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${backendUrl}/api/reports/pdf/balance-sheet?${queryString.stringify(
                  rest
                )}`}
                className="btn print"
              >
                PDF <PDFIcon color="#ff0000" />
              </a>
              <button className="btn print" onClick={onDownloadExcelData}>
                Excel <ExcelIcon color="#008000" />
              </button>
            </div>
          </header>
          <div className="container">
            {/* ASSETS */}
            <div>
              <div className="fw-bold p-2 mb-2 bg-low-blue text-white">
                <p>ASSETS</p>
              </div>
              <div>
                <div className="fw-semibold p-2 mb-2 bg-light text-white">
                  <p className="container">Current Assets</p>
                </div>
                <div className="container px-5">
                  <div className="d-flex justify-content-between">
                    <p className="fs-6 mb-1">Current Assets</p>
                    <p className="fs-6 mb-1">{data.main?.CURRENTASSET}</p>
                  </div>
                  <div>
                    <div className="d-flex justify-content-between">
                      <p className="fs-6 mb-1">Cash & Cash Equivalent</p>
                      <p className="fs-6 mb-1">
                        {data.main?.CASHANDCASHEQUIVALENT}
                      </p>
                    </div>

                    <div className="d-flex justify-content-between">
                      <p className="fs-6 mb-1">Account Receivables</p>
                      <p className="fs-6 mb-1">
                        {data.main?.ACCOUNTSRECEIVABLE}
                      </p>
                    </div>
                    {/* <div className="d-flex justify-content-between">
                    <p className="fs-6 mb-1">Retained Earnings</p>
                    <p className="fs-6 mb-1">27,0000</p>
                  </div> */}
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <p className="fw-bold fs-6 mb-1 mx-4">
                      Total Current Assets
                    </p>
                    <span className="fw-bold fs-6 mb-1">
                      {data.main?.TotalCurrentAssets}
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <div className="fw-semibold p-2 mb-2 bg-light text-white">
                  <p className="container">Fixed Assets</p>
                </div>
                <div className="container px-5">
                  <div className="d-flex justify-content-between">
                    <p className="fs-6 mb-1">Fixed Assets</p>
                    <p className="fs-6 mb-1">{data.main?.FIXEDASSET}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="fs-6 mb-1">Non Current Assets</p>
                    <p className="fs-6 mb-1">{data.main?.NONCURRENTASSETS}</p>
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <p className="fw-bold fs-6 mx-4">Total Fixed Assets</p>
                    <span className="fw-bold fs-6">
                      {data.main?.TotalFixedAssets}
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <div className="fw-semibold p-2 mb-2 bg-light text-white">
                  <p className="container">Other Assets</p>
                </div>
                <div className="container px-5">
                  <div className="d-flex justify-content-between">
                    <p className="fs-6 mb-1">Accumulated Depreciation</p>
                    <p className="fs-6 mb-1">
                      {data.main?.ACCUMULATEDDEPRECIATION}
                    </p>
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <p className="fw-bold fs-6 mb-1 mx-4">Total Other Assets</p>
                    <span className="fw-bold fs-6 mb-1">
                      {data.main?.ACCUMULATEDDEPRECIATION}
                    </span>
                  </div>
                </div>
              </div>

              <div className="fw-bold p-1 mt-3 bg-light text-white">
                <div className="d-flex justify-content-between align-items-center container px-5">
                  <p className="fs-6 mb-1">Total Assets</p>
                  <p className="fs-6 mb-1">{data.main?.TotalAssets}</p>
                </div>
              </div>
            </div>

            {/* Liabilities and Owner's Equity */}
            <div className="mt-5">
              <div className="fw-bold p-2 mb-2 bg-low-blue text-white">
                <p>Liabilities and Owner's Equity</p>
              </div>
              <div>
                <div className="fw-semibold p-2 mb-2 bg-light text-white">
                  <p className="container">Current Liabilities</p>
                </div>
                <div className="container px-5">
                  <div className="d-flex justify-content-between">
                    <p className="fs-6 mb-1">Current Liabilities </p>
                    <p className="fs-6 mb-1">{data.main?.CURRENTLIABILITIES}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="fs-6 mb-1">Account Payable </p>
                    <p className="fs-6 mb-1">{data.main?.ACCOUNTSPAYABLE}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="fs-6 mb-1">Non Current Liabilities </p>
                    <p className="fs-6 mb-1">
                      {data.main?.NONCURRENTLIABILITY}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="fs-6 mb-1">Long Term Liabilities </p>
                    <p className="fs-6 mb-1">
                      {data.main?.LONGTERMLIABILITIES}
                    </p>
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <p className="fw-bold fs-6 mb-1 mx-4">
                      Total Current Liabilities
                    </p>
                    <span className="fs-6 mb-1 fw-bold">
                      {data.main?.TotalCurrentLiabilities}
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <div className="fw-semibold p-2 mb-2 bg-light text-white">
                  <p className="container">Owner's Equity</p>
                </div>
                <div className="container px-5">
                  <div className="d-flex justify-content-between">
                    <p className="fs-6 mb-1">Owner's Funds </p>
                    <p className="fs-6 mb-1">{data.main?.OWNEREQUITY}</p>
                  </div>
                  {/* <div className="d-flex justify-content-between">
                  <p className="fs-6 mb-1">Other </p>
                  <p className="fs-6 mb-1">5000000</p>
                </div> */}
                  <div className="d-flex justify-content-end mt-3">
                    <p className="fw-bold fs-6 mb-1 mx-4">
                      Total Owner's Equity
                    </p>
                    <span className="fw-bold fs-6 mb-1">
                      {data.main?.OWNEREQUITY}
                    </span>
                  </div>
                </div>
              </div>
              <div className="fw-bold p-1 mt-5 bg-light text-white">
                <div className="d-flex justify-content-between align-items-center container px-5">
                  <p>Total Liabilities and Owner's Equity</p>
                  <p>{data.main?.TotalLiabilitiesandOwnerEquity}</p>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="pagination">
        
          <div className="pagination_left">
            <p className="m-0 p-0">Show</p>
            <select name="rows" className="form-select ">
              <option value="10">10 rows</option>
              <option value="20">20 rows</option>
              <option value="30">30 rows</option>
              <option value="40">40 rows</option>
            </select>
          </div>

    
          <div className="pagintion_right">
            <Pagination>
              <Pagination.Item active>{1}</Pagination.Item>
              <Pagination.Item>{2}</Pagination.Item>

              <Pagination.Ellipsis className="border-none" />

              <Pagination.Item>{6}</Pagination.Item>
              <Pagination.Next className="next" />
            </Pagination>
          </div>
        </div> */}
        </div>
        <ModalLoader show={isfetchingExcel || isFetching} />
      </main>
    </IsPrivileged>
  );
}

export default BalanceSheet;
