import Modal from "react-bootstrap/Modal";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useMutation, useQueries, useQuery } from "react-query";
import { toast } from "react-toastify";
import Select from "react-select";

import { useAuth } from "../../hooks/useAuth";
import { sendFormData, fetchActionsUtil } from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import Avatar from "../utils/Avatar";
import { appSettings, backendApis } from "../../config";
import CloseIcon from "mdi-react/CloseIcon";
import { uniqBy } from "lodash";
import { uniq } from "lodash";
import { queryActions } from "../../utils/reactQueryActions";
const apis = [backendApis];

const initials = {
  profilePics: "",
  Name: "",
  AccessLavel: "",
  Warehouse: "",
  username: "",
  Department: "",
  email: "",
  Branch: "",
  Staff_ID: "",
  userType: "core",
  initialUserType: "",
};

const allDepartment = [
  "",
  "Admin",
  "Accountant",
  "Cashier",
  // "Content Management",
  "Contracts",
  "HR",
  "Sales",
  "Waiter",
  // "Shout Out",
  "Store",
  "Warehouse",
  "Production",
  "Sales Manager",
  "Operations",
  "Business Development",
  "Procurement",
  "QHSE",
  "Maintenance",
  "Document Control",
  "Government InvexERP",
  "Supply and Logistics",
  "Hospital Management Board",
  "Primary Healthcare Center",
  "Rivers State Ministry of Health",
];

const alLevels = ["", "High", "Low"];

const branches = ["", "HQ", "Small Branch"];

const userType = ["core", "passive"];

const EditUserAccountModal = (props) => {
  const { backendUrl, brachData, token } = useAuth();

  const [inputData, setInputData] = useState(initials);

  const [defaultDepartmentValue, setDepartmentValue] = useState(null);
  //   const [userData, setUserData] = useState({});

  const fechIt = useCallback(async () => {
    const getData = await fetchActionsUtil(
      `${backendUrl}/api/users/${props.userId}`,
      "GET"
    );

    const {
      Name,
      AccessLavel,
      Warehouse,
      username,
      Department,
      email,
      Branch,
      Title,
      userType,
    } = getData?.data;
    setInputData({
      Name: Name ? Name : "",
      AccessLavel: AccessLavel ? AccessLavel : "",
      Warehouse: Warehouse ? Warehouse : "",
      username: username ? username : "",
      Department: Department ? Department : "",
      email: email ? email : "",
      Branch: Branch ? Branch : "",
      profilePics: "",
      Staff_ID: props.userId,
      Title: Title ? Title : "",
      userType: userType ? userType : "core",
      initialUserType: userType ? userType : "core",
    });

    setDepartmentValue({ label: Department, value: Department });
  }, [backendUrl, props.userId]);

  useEffect(() => {
    props.userId && fechIt();
  }, [props.userId, fechIt, props.show]);

  const fetchcompanyDetails = async (url) => {
    const res = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    });

    const data = await res.json();

    let companyData = data?.company;

    return companyData;
  };

  const company = useQuery(
    ["GET_COMPANY_DETAILS"],
    () => fetchcompanyDetails(`${backendUrl}/api/company/details`),
    {}
  );

  const fetchAllBranchStaff = async (endpoint) => {
    try {
      const res = await fetch(endpoint);

      const data = await res.json();

      return { ...data, url: endpoint };
    } catch (error) {
      throw new Error(`Failed to fetch ${endpoint}`);
    }
  };

  const queries = useQueries(
    apis
      ?.map((company) =>
        company.map((api) => ({
          queryKey: ["user", api.name],
          queryFn: () => fetchAllBranchStaff(`${api.url}/api/users`),
        }))
      )
      .flat()
  );

  const staffUserDetails = useMemo(() => {
    if (
      queries &&
      queries.length > 0 &&
      Boolean(queries[0]) &&
      process.env.REACT_APP_SITE_TITLE === "InvexERP"
    ) {
      const companies = queries?.map((query) => query.data);

      const staff = companies;

      const companyStaff = staff
        ?.map((user) => {
          return user?.staff?.map((staf) => {
            return { ...staf, url: user?.url };
          });
        })
        .flat();

      const uniqStaff = uniqBy(companyStaff, "username");

      function findUserBranches(username) {
        return companyStaff.filter((url) => url?.username === username);
      }

      const userBranches = uniqStaff.map((branch) => {
        const found = findUserBranches(branch?.username);

        const urls = found.map((url) => url?.url);
        return { ...branch, url: urls };
      });

      const uniqueSTaffWithStaffType = userBranches?.map((user) => ({
        ...user,
        userType: user?.userType ? user?.userType : "core",
      }));

      const totalCompanyCoreUsers =
        company?.data?.coreUsers || company?.data?.Num_Users;
      const totalCompanyPassiveUsers = company?.data?.passiveUsers || 0;

      const currentCoreUsers = uniqueSTaffWithStaffType.filter(
        (user) => user?.userType === "core"
      ).length;

      const currentPassiveUsers = uniqueSTaffWithStaffType.filter(
        (user) => user?.userType === "passive"
      ).length;

      return {
        staff: uniqueSTaffWithStaffType,
        activatedCoreUsers: currentCoreUsers,
        activatedPassiveUsers: currentPassiveUsers,
        totalCoreUsers: totalCompanyCoreUsers,
        totalPassiveUsers: totalCompanyPassiveUsers,
      };
    } else {
      return {};
    }
  }, [queries, company?.data]);

  // console.log(inputData);

  // const { data, isFetching } = useQuery(
  //   ["FETCH_WAREHOUSE"],
  //   () => fetchActionsUtil(`${backendUrl}/api/warehouse`, "GET"),
  //   {
  //     keepPreviousData: true,
  //   }
  // );

  // const mergedWarehouse = useMemo(() => {
  //   return data?.warehouses?.map((el) => ({
  //     label: el.W_name,
  //     value: el.W_ID,
  //   }));
  // }, [data]);

  // const defaltWarehouse = useMemo(() => {
  //   try {
  //     return inputData?.Warehouse?.split(",")?.map((el) => ({
  //       // label: mergedWarehouse.find(({ value }) => value === el).label,
  //       // value: el,
  //       label: el,
  //       value: el,
  //     }));
  //   } catch (error) {}
  // }, [inputData?.Warehouse]);

  // console.log(defaltWarehouse);

  const OnChangeInput = (e) => {
    const { name, value, files } = e.target;
    if (name === "profilePics")
      return setInputData({ ...inputData, [name]: files[0] });
    setInputData({ ...inputData, [name]: value });
  };

  const editAccount = useMutation(
    (payload) =>
      sendFormData(`${backendUrl}/api/users/edit-user-account`, payload),
    {
      onSuccess: () => {
        props.refetch();
        toast.success("Account Updated Successfully");
        props.refetch();
        props.onHide();
      },
      onError: ({ message = "" }) => {
        toast.error(message);
      },
    }
  );

  const saveData = async (e) => {
    e.preventDefault();

    const { profilePics, ...rest } = inputData;
    rest.brachData = JSON.stringify(brachData);

    if (typeof rest?.Warehouse === "string") {
    } else {
      rest.Warehouse = rest?.Warehouse?.map((d) => d?.value);
    }

    const formData = new FormData();
    for (const [key, value] of Object.entries(rest)) {
      formData.append(key, value);
    }

    if (profilePics) {
      formData.append(
        "profilePics",
        profilePics,
        `${rest.Staff_ID}_profilePics`
      );
    }

    if (process.env.REACT_APP_SITE_TITLE === "InvexERP") {
      if (inputData.userType !== inputData.initialUserType) {
        toast.error(
          `Please select  ${inputData.initialUserType} from the user type`
        );
        return;
      }
    }

    if (
      await ConfirmDialog({
        title: "Edit Profile",
        description: `Are you sure you want to EDIT ${
          props.modalName
        }'s Profile`,
      })
    ) {
      editAccount.mutate(formData);
    }
  };

  // useEffect(() => {
  //   if (
  //     inputData.Department === "Accountant" ||
  //     inputData.Department === "Admin" ||
  //     inputData.Department === "Procurement" ||
  //     inputData.Department === "HR" ||
  //     inputData.Department === "Warehouse" ||
  //     inputData.Department === "Production" ||
  //     inputData.Department === "Content Management" ||
  //     inputData.Department === ""
  //   ) {
  //     setInputData({ ...inputData, userType: "core" });
  //   } else {
  //     setInputData({ ...inputData, userType: "passive" });
  //   }
  // }, [inputData.Department]);

  const getDepartments = async () => {
    let response = await fetch(`${backendUrl}/api/users/departments`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.departments = uniq([
      ...allDepartment,
      ...data.departments.map((el) => el.Department),
    ]);
    return data;
  };
  const { data: { departments } = { departments: [] } } = useQuery(
    [queryActions.GET_DEPARTMENTS],
    () => getDepartments(),
    {}
  );

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div>
          <div>
            <div className="d-flex justify-content-between">
              <h6>Edit Details</h6>
              <div role="button" className="text-lg" onClick={props.onHide}>
                <CloseIcon />
              </div>
            </div>
            <p className="text-muted">{`Edit ${props.modalName}'s Details`}</p>
          </div>
          <form className="mt-3" onSubmit={saveData}>
            <div className="row">
              <div className="col-2 p-3">
                <Avatar
                  onFileUpload={OnChangeInput}
                  image={inputData.profilePics}
                  name={inputData.Name}
                  style={{ width: "7rem", height: "7rem" }}
                  nameInput="profilePics"
                  userId={props.userId}
                />
              </div>
              <div className="col-10 p-3">
                <div className="row">
                  <div className="col-6">
                    {/*  */}
                    <div className="mb-3">
                      <label className="col-form-label fw-bold">
                        Full Name
                      </label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          value={inputData.Name}
                          name="Name"
                          onChange={OnChangeInput}
                        />
                      </div>
                    </div>

                    <div className="mb-3">
                      <label className="col-form-label fw-bold">
                        Access Level
                      </label>
                      <div>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={inputData.AccessLavel}
                          name="AccessLavel"
                          onChange={OnChangeInput}
                        >
                          {alLevels.map((a, i) => (
                            <option key={i} value={a}>
                              {a}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {process.env.REACT_APP_SITE_TITLE === "InvexERP" && (
                      <div className="mb-3 row">
                        <label className="col-form-label fw-bold">
                          User Type
                        </label>
                        <div>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={inputData.userType}
                            name="userType"
                            onChange={OnChangeInput}
                          >
                            {userType.map((a, i) => (
                              <option key={i} value={a}>
                                {a}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                    {/*  */}
                    <div className="mb-3">
                      <label className="col-form-label fw-bold">Email</label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          value={inputData.email}
                          name="email"
                          onChange={OnChangeInput}
                        />
                      </div>
                    </div>
                    {/*  */}
                  </div>
                  {/* Second side inputs */}
                  <div className="col-6">
                    <div className="mb-3">
                      <label className="col-form-label fw-bold">Title</label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          value={inputData.Title}
                          name="Title"
                          onChange={OnChangeInput}
                        />
                      </div>
                    </div>
                    {/*  */}
                    <div className="mb-3 d-none">
                      <label className="col-form-label fw-bold">
                        User Name
                      </label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          value={inputData.username}
                          name="username"
                          onChange={OnChangeInput}
                          readOnly
                        />
                      </div>
                    </div>
                    {/*  */}
                    <div className="mb-3">
                      <label className="col-form-label fw-bold">
                        Department
                      </label>
                      <div>
                        {defaultDepartmentValue && (
                          <Select
                            classNamePrefix="form-select"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            isSearchable={true}
                            defaultValue={defaultDepartmentValue}
                            onChange={(selected) => {
                              setInputData({
                                ...inputData,
                                Department: selected?.value,
                              });
                            }}
                            // defaultValue={{
                            //   value: inputData?.Department,
                            //   label: inputData?.Department,
                            // }}

                            options={
                              departments
                                .filter((department) =>
                                  !appSettings.isMedbury &&
                                  [
                                    "Hospital Management Board",
                                    "Primary Healthcare Center",
                                    "Rivers State Ministry of Health",
                                  ].includes(department)
                                    ? false
                                    : true
                                )
                                .map((d, i) => ({
                                  label: d,
                                  value: d,
                                })) || []
                            }
                            isClearable
                          />
                        )}

                        {/* <select
                          className="form-select"
                          aria-label="Default select example"
                          value={inputData.Department}
                          name="Department"
                          onChange={OnChangeInput}
                        >
                          {departments
                            .filter((department) =>
                              !appSettings.isMedbury &&
                              [
                                "Hospital Management Board",
                                "Primary Healthcare Center",
                                "Rivers State Ministry of Health",
                              ].includes(department)
                                ? false
                                : true
                            )
                            .map((d, i) => (
                              <option key={i} value={d}>
                                {d}
                              </option>
                            ))}
                        </select> */}
                      </div>
                    </div>
                    {/*  */}

                    {/* {inputData.Department === "Warehouse" && (
                      <div className="mb-3 row">
                        <label className="col-sm-4 col-form-label fw-bold text-nowrap">
                          Choose Warehouse
                        </label>
                        <div>
                          <Select
                            closeMenuOnSelect={true}
                            isLoading={isFetching}
                            isMulti
                            isSearchable={true}
                            defaultValue={defaltWarehouse}
                            onChange={(select) => {
                              setInputData({
                                ...inputData,
                                Warehouse: select,
                              });
                            }}
                            options={mergedWarehouse}
                          />
                        </div>
                      </div>
                    )} */}
                    {/*  */}

                    <div className="mb-3">
                      <label className="col-form-label fw-bold">Branch</label>
                      <div>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={inputData.Branch}
                          name="Branch"
                          onChange={OnChangeInput}
                        >
                          {branches.map((d, i) => (
                            <option key={i} value={d}>
                              {d}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {/*  */}
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      <div className="d-flex justify-content-end m-4">
        <button className="btn bg print mx-4" onClick={props.onHide}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={saveData}>
          Update details
        </button>
      </div>
    </Modal>
  );
};

export default EditUserAccountModal;
