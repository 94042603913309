import { Button, Table } from "react-bootstrap";
import PaymentAccountDialog from "../PaymentAccountDialog";
import { useIsCashier } from "../../utils/hooks";
import { useAuth } from "../../hooks/useAuth";
import { cloneDeep, isEmpty, lowerCase, uniq } from "lodash";
import { useMutation, useQuery } from "react-query";
import { queryActions } from "../../utils/reactQueryActions";
import { toast } from "react-toastify";
import currency from "currency.js";
import { useEffect, useState } from "react";
import { useMemo } from "react";
import { last } from "lodash";
import { useNavigate } from "react-router-dom";
import { uniqBy } from "lodash";
import { Fragment } from "react";
import ApprovalBadge from "../utils/ApprovalBadge";
import { fetchActionsUtil, formatDate } from "../../utils/helpers";
import { useStoreState } from "easy-peasy";
import CurrencyInput from "react-currency-input-field";
import ConfirmDialogue from "../ConfirmDialogue";
import ModalLoader from "../utils/ModalLoader";

export default function AllowanceRequest({ requisition }) {
  const isCashier = useIsCashier();
  const navigate = useNavigate();
  const { backendUrl, token, user: authUser } = useAuth();
  const generalSettings = useStoreState((state) => state.generalSettings);

  const [tableData, setTableData] = useState(
    requisition?.loanDetails ? JSON.parse(requisition?.loanDetails) : {}
  );

  const postAllowance = async (payload) => {
    let response = await fetch(
      `${backendUrl}/api/requisition/pay-allowance/${requisition.requestid}`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const postAllowanceMutation = useMutation(
    (payload) => postAllowance(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/invoice/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.vendors = [
      {
        value: "",
        label: "One-time vendor",
      },
      ...data?.vendors.map((el) => ({
        label: el.CompanyName,
        value: el.Vendor_ID,
      })),
    ];

    data.banks = data?.banks
      .filter((el) => el?.BankName && el.currency !== "USD")
      .map((el) => ({
        label: el.BankName,
        value: el.BankName,
      }));

    data.branch = [
      {
        value: "General",
        label: "General",
      },
    ];

    data.imprest = data?.imprest
      .filter((el) => el?.Description)
      .map((el) => ({
        ...el,
        label: el?.Description,
        value: el?.Description,
      }));

    return data;
  };

  const {
    data = { banks: [], vendors: [], branch: [], imprest: [] },
    isLoading,
  } = useQuery([queryActions.INVOICE_SETUP], () => fetchSetUpData(), {
    keepPreviousData: true,
  });

  const postPayment = async () => {
    const formValues = await PaymentAccountDialog({
      data,
      previouslySelectedAccount: {
        By: "",
        From: "",
      },
      payFromLabel: "From",
      payByLabel: "By",
      amount: 0,
      hasRemark: true,
    });

    if (formValues && !isEmpty(formValues)) {
      postAllowanceMutation.mutate({
        ...formValues,
        By: formValues.reimburseBy,
        From: formValues.reimburseFrom,
      });
    }

    // console.log(formValues);
  };

  const postExpense = ({ job, allowances }) => {
    navigate("/expenses-entry", {
      state: {
        expenses: allowances.map((el) => ({
          ...el,
          Particulers: el.description,
          Amount: currency(el?.amount).value,
        })),
        job,
        Vendor_ID: "",
        requisitionApprovedBy: last(requisition?.requisitiontracks)
          .receivedbyUser.Name,
        requisitionTitle: requisition?.title,
        currency: generalSettings?.prevailingCurrency,
        requestid: requisition.requestid,
      },
    });
  };

  const handleInputChange = ({ name, value, _id }) => {
    const oldTableData = tableData;

    oldTableData["details"]?.map((el) => {
      if (el?._id === _id) {
        el[name] = parseFloat(value);
      }

      return el;
    });

    setTableData({ ...oldTableData });
  };

  const allowanceData = useMemo(() => {
    // const requestData = requisition?.loanDetails
    //   ? JSON.parse(requisition?.loanDetails)
    //   : {};

    // requestData.allowancesByJob = uniqBy(requestData.details, "job").map(
    //   (el) => ({
    //     jobNumber: el.job,
    //     title: el.tittle,
    //     paymentStatus: el.paymentStatus,
    //     allowances: requestData.details.filter(
    //       (detail) => detail.job === el.job
    //     ),
    //   })
    // );

    const requestData = {};

    requestData.allowancesByJob = uniqBy(tableData.details, "job").map(
      (el) => ({
        jobNumber: el.job,
        title: el.tittle,
        paymentStatus: el.paymentStatus,
        allowances: tableData.details.filter((detail) => detail.job === el.job),
      })
    );

    return { ...requestData };
  }, [tableData?.details]);

  const postAllowanceAmount = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/requisition/change-allowance-amount/${
          requisition.requestid
        }`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const saveAmount = async () => {
    if (
      await ConfirmDialogue({
        title: "Save Amount",
        description: "Are you sure you want to save amount",
      })
    ) {
      postAllowanceAmount.mutate(tableData);
    }
  };

  return (
    <div className="rounded mb-3">
      {/* 
      {JSON.stringify(allowanceData?.allowancesByJob)} */}
      <div className="d-flex justify-content-between">
        <div>
          <p className="mb-3 fw-bold">
            <span>Employee Name:</span> {tableData?.employee}
          </p>
        </div>
      </div>
      {allowanceData?.allowancesByJob &&
        allowanceData?.allowancesByJob.map((el, index) => (
          <Fragment key={index}>
            <div className="d-flex justify-content-between">
              <div>
                <p className="mb-3">
                  <span>Job Number:</span> {el.job}
                </p>
                <p className="mb-3 fw-6">
                  <span>Job Title:</span> {el.title}
                </p>
              </div>
              {requisition?.status.includes("Approved & Closed") && (
                <div>
                  {el?.paymentStatus === "Paid" ? (
                    <ApprovalBadge
                      text={"Paid"}
                      className="approval"
                      bg="success"
                    />
                  ) : isCashier ? (
                    <Button
                      variant="light-blue"
                      onClick={() =>
                        postExpense({
                          job: {
                            title: el.title,
                            jobNumber: el.jobNumber,
                          },
                          allowances: el.allowances,
                        })
                      }
                    >
                      Post Payment
                    </Button>
                  ) : null}
                </div>
              )}
            </div>
            <Table key={index} className="product-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Allowance</th>
                  <th>Job Done</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {el.allowances.map((detail, ind) => (
                  <tr key={ind}>
                    <td>
                      {detail?.overtimeDate
                        ? formatDate(detail?.overtimeDate)
                        : "..."}{" "}
                      -{" "}
                      {detail?.overtimeDateTo
                        ? formatDate(detail?.overtimeDateTo)
                        : "..."}
                    </td>
                    <td>{detail.name}</td>
                    <td>{detail.description}</td>
                    <td>
                      {/* {currency(detail.amount, {
                        symbol: "",
                      }).format()} */}

                      <CurrencyInput
                        className="form-control border-0 px-0"
                        name="amount"
                        value={detail?.amount}
                        // onKeyDown={(e) => {
                        //   if (e.keyCode === 13) {
                        //     console.log(e?.target?.value);
                        //     e.target.blur();
                        //   }
                        // }}
                        onValueChange={(value, name) =>
                          handleInputChange({
                            name,
                            value,
                            _id: detail?._id,
                          })
                        }
                        // disableGroupSeparators
                        decimalsLimit={2}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Fragment>
        ))}

      {["pending", "approved sent"].includes(lowerCase(requisition.status)) ? (
        <div className="d-flex justify-content-end my-4">
          <button className="mx-4 btn btn-primary" onClick={saveAmount}>
            Save Amount
          </button>
        </div>
      ) : null}

      <ModalLoader show={postAllowanceAmount.isLoading} />
    </div>
  );
}
